import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import LogoIcon from '../Images/Logo - Icon.png'
import LogoBanner from '../Images/Logo - Banner.png'

// This file defines the logo displayed in the header, and which variant appears based on the screen size.

function HeaderLogo() {

    {/* This section determines which logo will display based on screen width. If Mobile, the Icon will appear. Else, the Logo with text will appear. */}
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))

    function LogoSize(props) {

        const isLarge = props.isLarge

        if (isLarge) {

            return (<img src={LogoBanner} id='header-logo' onClick={() => window.location.href = '/'} />)

        }

        return (<img src={LogoIcon} id='header-logo' onClick={() => window.location.href = '/'} />)

    }

    return (

        <LogoSize isLarge={matches}/>

    )
}

export default HeaderLogo