import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SiteTitle from '../Components/SiteTitle';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SecurityIcon from '@mui/icons-material/Security';
import TIPTFig from '../Images/Telstra - TIPT - Figure 1.svg'
import HubIcon from '@mui/icons-material/Hub';
import PublicIcon from '@mui/icons-material/Public';
import SpeedIcon from '@mui/icons-material/Speed';
import GppGoodIcon from '@mui/icons-material/GppGood';
import HandymanIcon from '@mui/icons-material/Handyman';
import SensorsIcon from '@mui/icons-material/Sensors';
import Mobile1 from '../Images/Telstra - Mobile - Figure 1.svg'
import Mobile2 from '../Images/Telstra - Mobile - Figure 2.svg'
import Mobile3 from '../Images/Telstra - Mobile - Figure 3.svg'
import Mobile4 from '../Images/Telstra - Mobile - Figure 4.svg'
import Mobile5 from '../Images/Telstra - Mobile - Figure 5.svg'
import Mobile6 from '../Images/Telstra - Mobile - Figure 6.svg'
import Mobile7 from '../Images/Telstra - Mobile - Figure 7.svg'
import Mobile8 from '../Images/Telstra - Mobile - Figure 8.svg'
import Mobile9 from '../Images/Telstra - Mobile - Figure 9.svg'
import Mobile10 from '../Images/Telstra - Mobile - Figure 10.svg'

function TelstraServices() {
    SiteTitle("Bizcomm | Telstra Services")

    const iconStyling = { fontSize: '5rem', color: '#3f51bf' }

    return (
        <>

            <section id='telstra-splash' class='section sub-splash'>

                <h1><span>Telstra Services</span></h1>

            </section>

            <ThemeProvider
                theme={createTheme({
                    breakpoints: {
                        values: {
                            desktop: 1200,
                            laptop: 900,
                            tablet: 600,
                            mobile: 0,
                        },
                    },
                })}
            >

                <section id='telstra-content' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={4}>

                            <Grid mobile={4} tablet={3} laptop={3} desktop={3}>

                                <h1 class='content-h1'>Telstra</h1>

                                <p class='content-p'>
                                    As a whole-of business Telstra Enterprise Partner, we
                                    can offer end to end support. Whether your business
                                    requires support services on an existing phone system
                                    or looking to migrate to a Hosted phone solution, our
                                    team is keen to offer our services. We can assist with
                                    the migration of services such as SIP services to Telstra
                                    IP Telephony Service (TIPT) or Microsoft Operator Connect
                                    on Teams. We can review your mobile fleet and offer
                                    the best value plans with Telstra. We can help optimise
                                    your phone and data services, internet setup, wireless
                                    LAN (Wi-Fi) and even help track your vehicle fleet with
                                    the Navman Teletrac solution from Telstra. We can also
                                    help you move your Microsoft services to Telstra, so the
                                    business can benefit from the rebate via available tech
                                    fund offers. We will plan every step of the way with you
                                    to ensure a smooth transition.
                                </p>

                            </Grid>

                            <Grid display="flex" justifyContent="center" mobile={4} tablet={1} laptop={1} desktop={1}>

                                <div id='telstra-services-logo' />

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='telstra-tipt' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={4}>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <h1 class='content-h1'>TIPT – Telstra IP Telephony</h1>

                                <p class='content-p'>
                                    TIPT is a comprehensive office telephony platform offering
                                    a consistent experience across multiple devices and locations
                                    helping hybrid workers to be more productive.<br />
                                    TIPT offers a seamless user experience giving your teams
                                    office telephony functionality and collaboration tools whether
                                    they're in the office, at home, or on the go. If telephony is
                                    important to your business, then let us help migrate your
                                    business phone to TIPT.<br />
                                    Staff can work anywhere and still handle calls like a pro.
                                    The best part is you don’t have to worry about your phone
                                    system going down. With business continuity, calls can be
                                    handled on the mobile, Webex, teams or even the good
                                    old/new desk phone.
                                </p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <h2 class='content-h2' >Key Features</h2>

                            </Grid>

                            <Grid mobile={2} tablet={2} laptop={1} desktop={1}>

                                <PhonelinkRingIcon sx={iconStyling} />

                                <h3 class='content-h3'>Powerful calling functions and mobile integration with Liberate</h3>

                                <p class='content-p'>
                                    A cloud calling platform for the modern hybrid worker – where you need it – in the office, on the road or at home.
                                </p>

                            </Grid>

                            <Grid mobile={2} tablet={2} laptop={1} desktop={1}>

                                <ConnectWithoutContactIcon sx={iconStyling} />

                                <h3 class='content-h3'>Meet, message, and collaborate with a modern and easy to use Webex interface</h3>

                                <p class='content-p'>
                                    A single user interface, across devices, allows your team to collaborate simply and effectively wherever they are located.
                                </p>

                            </Grid>

                            <Grid mobile={2} tablet={2} laptop={1} desktop={1}>

                                <GroupAddIcon sx={iconStyling} />

                                <h3 class='content-h3'>Easily add new users and scale quickly via our self-service portal</h3>

                                <p class='content-p'>
                                    Provision new users and scale up and down as needed, so you can you adapt your service as your business changes.
                                </p>

                            </Grid>

                            <Grid mobile={2} tablet={2} laptop={1} desktop={1}>

                                <SecurityIcon sx={iconStyling} />

                                <h3 class='content-h3'>Auto-feature updates</h3>

                                <p class='content-p'>
                                    Cloud-based collaboration ensures you’ll always have the latest security updates and features.
                                </p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <h2 class='content-h2'>How it works</h2>

                                <p class='content-p'>
                                    TIPT supports devices and handsets connected over Telstra’s Next IP MPLS network, across the Internet, and natively via the 4G/5G mobile network with Liberate. TIPT soft clients can connect users across MPLS or the Internet.

                                    Even when connecting over the Internet, TIPT ensures your calls are secure, encrypting traffic with TLS 1.2 and sRTP, using 256Bit encryption.

                                    The TIPT platform is fully geo-redundant between Sydney and Melbourne and is designed with voice quality in mind. TIPT provides PoPs in all major cities to ensure the shortest path from your handset/device to our platform.
                                </p>

                                <img class='content-img' src={TIPTFig} alt='How TIPT Works' />

                                <p class='content-p'>For more information, visit the link <a href='https://www.telstra.com.au/business-enterprise/products/unified-communications/calling-and-productivity/tipt' target='blank_?' rel="noreferrer">here</a>.</p>

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='telstra-tid' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={12}>

                            <Grid mobile={12} tablet={12} laptop={12} desktop={12}>

                                <h1 class='content-h1'>TID – Telstra Internet Direct</h1>

                                <p class='content-p'>
                                    Telstra Internet Direct is a secure, high-performance internet
                                    service with exceptional availability and scalability. It is
                                    designed to support business-critical operations and provide
                                    a consistent, quality experience for your staff and customers.
                                    With Telstra Internet Direct, you have a dedicated,
                                    carrier-grade link to one of the largest internet backbones
                                    in Australia providing greater national coverage as well as
                                    the global internet.<br />
                                    Telstra offer more Points of Presence in Australia than other
                                    providers, plus more than 900 Points of Presence world-wide
                                    for ease and speed of access.<br />
                                    The high-capacity network has full redundancy and inbuilt
                                    security, backed by proactive monitoring and management. You
                                    also have the assurance of 24/7 helpdesk support, while an
                                    online portal lets you view, troubleshoot and manage your
                                    service.<br />
                                    Telstra Internet Direct gives you the flexibility to choose
                                    from a wide range of carriage types, speeds, usage and
                                    pricing, the option of IPv6 addressing, as well as a range
                                    of additional services.
                                </p>

                            </Grid>

                            <Grid mobile={12} tablet={12} laptop={12} desktop={12}>

                                <h2 class='content-h2'>Key Features</h2>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={4} desktop={4}>

                                <HubIcon sx={iconStyling} />

                                <h3 class='content-h3'>Redundancy paths and security built into the core network</h3>

                                <p class='content-p'>Multiple redundant paths to the global internet and built in security at multiple layers with 24/7 monitoring to protect your information.</p>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={4} desktop={4}>

                                <PublicIcon sx={iconStyling} />

                                <h3 class='content-h3'>Extensive access points across Australia and to the world</h3>

                                <p class='content-p'>Extensive coverage in Australia and overseas, and scale up easily with over 140 Points of Presence across Australia and 900 globally through our partners .</p>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={4} desktop={4}>

                                <SpeedIcon sx={iconStyling} />

                                <h3 class='content-h3'>Symmetrical speeds for fast data uploads and downloads</h3>

                                <p class='content-p'>Fast, consistent upload and download speed, even at peak times for enhanced cloud, video and VoIP experiences.</p>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={4} desktop={4}>

                                <GppGoodIcon sx={iconStyling} />

                                <h3 class='content-h3'>A premium, reliable, enterprise-grade internet connection offering Committed Information Rate</h3>

                                <p class='content-p'>Experience consistent, fast, secure performance via a dedicated, symmetrical, enterprise-grade connection which offers Committed Information Rate to one of Australia’s largest internet backbones.</p>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={4} desktop={4}>

                                <HandymanIcon sx={iconStyling} />

                                <h3 class='content-h3'>24/7 support for 365 days a year</h3>

                                <p class='content-p'>Technical helpdesk, plus an online fault logging helpdesk dedicated to TID to keep your operations running smoothly in more places.</p>

                            </Grid>

                            <Grid mobile={6} tablet={4} laptop={4} desktop={4}>

                                <SensorsIcon sx={iconStyling} />

                                <h3 class='content-h3'>A wide choice of features plus additional options</h3>

                                <p class='content-p'>A range of access types and speeds with an option of IPv6 addressing and integrating additional services.</p>

                            </Grid>

                            <Grid mobile={12} tablet={12} laptop={12} desktop={12}>

                                <p class='content-p'>For more information, visit the link <a href='https://www.telstra.com.au/business-enterprise/products/networks/adaptive-networks/internet-solutions/telstra-internet-direct' target='blank_?' rel="noreferrer">here</a>.</p>

                            </Grid>

                        </Grid>

                    </section >

                </section >

                <section id='telstra-mobile' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={20}>

                            <Grid mobile={20} tablet={20} laptop={20} desktop={20}>

                                <h1 class='content-h1'>Telstra Adaptive Mobility</h1>

                                <p class='content-p'>
                                    Telstra Adaptive Mobility is a modular Mobile, Mobile Broadband and Enterprise Wireless solution including flexible month-to-month no lock-in contracts and data sharing, along with managed service and security options for all your business needs.
                                </p>

                            </Grid>

                            <Grid mobile={20} tablet={20} laptop={20} desktop={20}>

                                <h2 class='content-h2'>What sets Telstra Adaptive Mobility apart?</h2>

                            </Grid>

                            <Grid mobile={10} tablet={5} laptop={4} desktop={4}>

                                <img src={Mobile1} alt='' />

                                <h3 class='content-h3'>The Telstra Mobile Network</h3>

                                <p class='content-p'>
                                    As Australia’s best mobile network, we provide more coverage in more places than any other Australian mobile network.
                                </p>

                            </Grid>

                            <Grid mobile={10} tablet={5} laptop={4} desktop={4}>

                                <img src={Mobile2} alt='' />

                                <h3 class='content-h3'>The speed of 5G</h3>

                                <p class='content-p'>
                                    Every Adaptive Mobility plan is now unleashed, so you can take advantage of the benefits of 5G.
                                </p>

                            </Grid>

                            <Grid mobile={10} tablet={5} laptop={4} desktop={4}>

                                <img src={Mobile3} alt='' />

                                <h3 class='content-h3'>Australia’s largest 5G network</h3>

                                <p class='content-p'>
                                    Telstra 5G reaches 85% of the Australian population with plans in place to roll out to 95% by 2025.
                                </p>

                            </Grid>

                            <Grid mobile={10} tablet={5} laptop={4} desktop={4}>

                                <img src={Mobile4} alt='' />

                                <h3 class='content-h3'>Managed Services</h3>

                                <p class='content-p'>
                                    Telstra and our partners help keep you up and running every day and plan for the future with managed services.
                                </p>

                            </Grid>

                            <Grid mobile={10} tablet={5} laptop={4} desktop={4}>

                                <img src={Mobile5} alt='' />

                                <h3 class='content-h3'>Managed Services Credit</h3>

                                <p class='content-p'>
                                    You can offset Managed services costs through credit from select Adaptive Mobility Mobile plans applied against eligible managed service modules. T&Cs apply.
                                </p>

                            </Grid>

                            <Grid mobile={10} tablet={5} laptop={4} desktop={4}>

                                <img src={Mobile6} alt='' />

                                <h3 class='content-h3'>Choice of data</h3>

                                <p class='content-p'>
                                    Adaptive Mobility plans give you the choice of tiered data volumes to suit each user and bundled inclusions.
                                </p>

                            </Grid>

                            <Grid mobile={10} tablet={5} laptop={4} desktop={4}>

                                <img src={Mobile7} alt='' />

                                <h3 class='content-h3'>Data sharing</h3>

                                <p class='content-p'>
                                    Data inclusions can be shared, helping to balance use across your business.
                                </p>

                            </Grid>

                            <Grid mobile={10} tablet={5} laptop={4} desktop={4}>

                                <img src={Mobile8} alt='' />

                                <h3 class='content-h3'>Telstra Enterprise Plus Fund</h3>

                                <p class='content-p'>
                                    Get rewarded with 10% of net monthly subscriptions banked into your fund.
                                </p>

                            </Grid>

                            <Grid mobile={10} tablet={5} laptop={4} desktop={4}>

                                <img src={Mobile9} alt='' />

                                <h3 class='content-h3'>Flexible hardware options</h3>

                                <p class='content-p'>
                                    BYO, lease or purchase handsets and devices outright, subsidised by your Telstra Enterprise Plus Fund.
                                </p>

                            </Grid>

                            <Grid mobile={10} tablet={5} laptop={4} desktop={4}>

                                <img src={Mobile10} alt='' />

                                <h3 class='content-h3'>Certified carbon neutral</h3>

                                <p class='content-p'>
                                    All Telstra Enterprise mobile plans and MBB plans are certified carbon neutral under the Australian Government’s Climate Active program, at no extra cost to customers.
                                </p>

                            </Grid>

                            <Grid mobile={20} tablet={20} laptop={20} desktop={20}>

                                <p class='content-p'>For more information, visit the link <a href='https://www.telstra.com.au/business-enterprise/products/mobility-solutions/plans-and-devices/adaptive-mobility' target='blank_?' rel="noreferrer">here</a>.</p>

                            </Grid>

                        </Grid>

                    </section >

                </section >

            </ThemeProvider >

        </>
    )
}

export default TelstraServices