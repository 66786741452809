import './Styles/App.css'
import { Routes, Route, useLocation } from 'react-router-dom'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from './Pages/Home'
import OurTeam from './Pages/OurTeam'
import TelstraServices from './Pages/TelstraServices'
import CiscoServices from './Pages/CiscoServices'
import MicrosoftServices from './Pages/MicrosoftServices'
import NavmanServices from './Pages/NavmanServices'
import ContactUs from './Pages/ContactUs'
import Privacy from './Pages/Privacy'
import Terms from './Pages/Terms'
import FAQ from './Pages/FAQ'
import Dashboard from './Pages/Dashboard'
import NotFound from './Pages/NotFound'

// This file determines which elements and pages are loaded based on the current URL.

function App() {
    return (
        <div className='App'>

            {/* Renders the Header component at all times */}
            <Header />

            {/* Defines what pages load based on the current url */}
            <Routes>

                < Route path='/' element={<Home />} />

                < Route path='/our-team' element={<OurTeam />} />

                < Route path='/telstra-services' element={<TelstraServices />} />

                < Route path='/cisco-services' element={<CiscoServices />} />

                < Route path='/microsoft-services' element={<MicrosoftServices />} />

                < Route path='/navman-services' element={<NavmanServices />} />

                < Route path='/contact-us' element={<ContactUs />} />

                < Route path='/terms' element={<Terms />} />

                < Route path='/privacy' element={<Privacy />} />

                < Route path='/faq' element={<FAQ />} />

                < Route path='/dashboard' element={<Dashboard/>} />

                < Route path='*' element={<NotFound/>} />

            </Routes>

            {/* Renders the Footer component at all times */}
            <Footer />

        </div>
    )
}

export default App
