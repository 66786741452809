import { useState, React } from 'react'
import { Button, FormControl, OutlinedInput, InputLabel, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

// This file defines the Searchbar displayed in the header, and how it shows and hides itself.

function Search() {

    const [isExtended, setIsExtended] = useState(false)

    const extendSearch = () => {
        setIsExtended(!isExtended)
    }

    return (
        <>

            <Button onClick={extendSearch}>

                <SearchIcon id='search-icon' class='nav-icon' />

            </Button>

            {
                isExtended &&
                <FormControl
                    color='primary'
                    variant='outlined'
                    margin='normal'
                    id='search-bar-container'
                >
                    <InputLabel htmlFor='search-bar'>
                        Search
                    </InputLabel>

                    <OutlinedInput
                        id='search-bar'
                        endAdornment={
                            <InputAdornment position='end'>
                                <SearchIcon />
                            </InputAdornment>
                        }
                        label='Business'
                    />
                </FormControl>
            }
        </>
    )
}

export default Search