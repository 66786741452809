import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SiteTitle from '../Components/SiteTitle';

function ContactUs() {
    SiteTitle("Bizcomm | Contact Us")
    return (
        <>

            <section id='contact-us-splash' class='section sub-splash'>

                <h1><span>Contact Us</span></h1>

            </section>

            <ThemeProvider
                theme={createTheme({
                    breakpoints: {
                        values: {
                            desktop: 1200,
                            laptop: 900,
                            tablet: 600,
                            mobile: 0,
                        },
                    },
                })}
            >

                <section id='contact-us-content' class='section'>

                    <section class='grid-container'>

                        <Grid container spacing={8} columns={4}>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <h3>Need help with something? Contact us now!</h3>

                                <p>Business Hours: 8am - 6pm AEST</p>
                                <p>Phone: 02 9828 3700</p>

                                <p>ABN: 54 620 611 467</p>
                                <p>Linkedin: <a href='https://linkedin.com/company/bizcomm-au/' target="_blank" rel="noreferrer">linkedin.com/company/bizcomm-au/</a></p>
                                <p>Address: First Floor, Unit 3 1002 Canley Vale Rd Wetherill Park NSW 2164</p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <iframe title='maps' width={'100%'} height={'100%'}  style={{border:0}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.4914822879164!2d150.89044439401806!3d-33.85122250421783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12974286cfef33%3A0x7b6841e9d23dbc73!2sBizcomm%20Pty%20Ltd!5e0!3m2!1sen!2sau!4v1707704273857!5m2!1sen!2sau" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />

                            </Grid>

                        </Grid>

                    </section>

                </section>

            </ThemeProvider>

        </>
    )
}

export default ContactUs