import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ReactTyped } from "react-typed";
import SiteTitle from '../Components/SiteTitle';
import Testimonials from '../Components/Testimonials';
import TelstraLogo from '../Images/Telstra Logo.svg'
import CiscoLogo from '../Images/Cisco Logo.svg'
import MicrosoftLogo from '../Images/Microsoft Logo.svg'
import NavmanLogo from '../Images/Teletrac Navman Logo.svg'
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import AustraliaIcon from '../Images/Australia Icon.svg'
import CachedIcon from '@mui/icons-material/Cached';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import MediationIcon from '@mui/icons-material/Mediation';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

//This file defines the Home page of the site, all the elements that appear and how they are organised.

function Home() {
    SiteTitle("Bizcomm | Home")

    const iconStyling = { fontSize: '5rem', color: '#3f51bf' }

    return (
        <>

            <section id='home-splash' class='section splash'>

                <div id='home-splash-header'>

                    <h1>Your <span id='switchy-text' style={{ color: '#0153ec' }}>
                        <ReactTyped
                            strings={["Telstra", "Cisco", "Microsoft", "Navman"]}
                            typeSpeed={200}
                            loop
                            backSpeed={20}
                            showCursor={true}
                        />
                    </span>
                    </h1>

                    <h1>services simplified.</h1>

                </div>

            </section>

            <ThemeProvider
                theme={createTheme({
                    breakpoints: {
                        values: {
                            desktop: 1200,
                            laptop: 900,
                            tablet: 600,
                            mobile: 0,
                        },
                    },
                })}
            >

                <section id='home-welcome' class='section'>

                    <section class='grid-container'>

                        <Grid container spacing={{ mobile: 1, tablet: 2, laptop: 3, desktop: 4 }} columns={8}>

                            <Grid mobile={8} tablet={8} laptop={8} desktop={8}>

                                <h1 class='section-h1'><span style={{ color: '#000000' }}>Welcome to </span> <span style={{ color: '#0153ec' }}>Bizcomm</span></h1>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <StoreMallDirectoryOutlinedIcon sx={iconStyling} />

                                <h3 class='content-h3'>Your one-stop shop</h3>

                                <p class='content-p'>Our team provides technical solutions to support customers with mobiles, cloud services, IP telephony, and complex data network requirements.</p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <img src={AustraliaIcon} alt='' style={{ height: '5rem' }} />

                                <h3 class='content-h3'>Proudly Australian</h3>

                                <p class='content-p'>Based in Sydney, we are committed to servicing Business and Enterprise Customers all over Australia.</p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <CachedIcon sx={iconStyling} />

                                <h3 class='content-h3'>End-to-end solutions</h3>

                                <p class='content-p'>Our staff can consult, design, implement, support, and help resolve your business communication issues.</p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <HandshakeOutlinedIcon sx={iconStyling} />

                                <h3 class='content-h3'>Committed to your success</h3>

                                <p class='content-p'>Our team is made up of certified IT professionals and Telecommunications Specialists, who love what we do and are dedicated to our work.</p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <MediationIcon sx={iconStyling} />

                                <h3 class='content-h3'>Making things simple</h3>

                                <p class='content-p'>Our team members have years of industry experience, so we can help make complicated processes simple.</p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <MonetizationOnOutlinedIcon sx={iconStyling} />

                                <h3 class='content-h3'>Providing the best value</h3>

                                <p class='content-p'>Bizcomm Solutions Consultants can combine the latest Telstra offering to ensure we deliver value, as well as connecting our customers to those that they want to reach.</p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <SupportAgentOutlinedIcon sx={iconStyling} />

                                <h3 class='content-h3'>We're there for you</h3>

                                <p class='content-p'>When you call or email us, you can rest assured that there is someone on the other end, working hard to help you address or resolve your queries.</p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <CheckCircleOutlineOutlinedIcon sx={iconStyling} />

                                <h3 class='content-h3'>Solving your problems</h3>

                                <p class='content-p'>Our job is to keep the green lights green. When something goes wrong, you can depend on our team being available to help make it right again.</p>

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='home-services' class='section'>

                    <section class='grid-container'>

                        <Grid container spacing={{ mobile: 1, tablet: 2, laptop: 3, desktop: 4 }} columns={4}>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <h1 id='home-services-header' class='section-h1' style={{ color: '#ffffff' }}>Our Services</h1>

                                <p class='content-p' style={{ color: '#fafafa' }}>
                                    The Bizcomm Team provides technical solutions to
                                    support Telstra Corporate and Business customers
                                    with mobiles, cloud services, IP telephony, and
                                    complex data requirements. Our staff can consult,
                                    design, implement, support, and help resolve your
                                    business communication issues. Bizcomm Solutions
                                    Consultants can combine the latest Telstra offering
                                    to ensure we deliver value, as well as connecting our
                                    customers to those that they want to reach. Our aim
                                    is to ensure technology works reliably and securely
                                    for our customers at the best possible value.
                                </p>

                            </Grid>

                            <Grid display="flex" justifyContent="center" mobile={2} tablet={2} laptop={1} desktop={1}>

                                <article class='home-service' onClick={() => window.location.href = '/telstra-services'}>

                                    <img class='home-service-svg' src={TelstraLogo} alt='Telstra Logo' />

                                </article>

                            </Grid>

                            <Grid display="flex" justifyContent="center" mobile={2} tablet={2} laptop={1} desktop={1}>

                                <article class='home-service' onClick={() => window.location.href = '/cisco-services'}>

                                    <img class='home-service-svg' src={CiscoLogo} alt='Cisco Logo' />

                                </article>

                            </Grid>

                            <Grid display="flex" justifyContent="center" mobile={2} tablet={2} laptop={1} desktop={1}>

                                <article class='home-service' onClick={() => window.location.href = '/microsoft-services'}>

                                    <img class='home-service-svg' src={MicrosoftLogo} alt='Microsoft Logo' />

                                </article>

                            </Grid>

                            <Grid display="flex" justifyContent="center" mobile={2} tablet={2} laptop={1} desktop={1}>

                                <article class='home-service' onClick={() => window.location.href = '/navman-services'}>

                                    <img class='home-service-svg' src={NavmanLogo} alt='Navman Logo' />

                                </article>

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='home-testimonials' class='section'>

                    <h1 class='section-h1' style={{ color: '#FFFFFF' }}>Testimonials</h1>

                    <Testimonials />

                </section>

            </ThemeProvider>

        </>
    )
}

export default Home