import React from 'react'
import Navigation from './Navigation'
import Search from './Search'
import SignIn from './SignIn'
import HeaderLogo from './HeaderLogo'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'


// This file organises the Header elements and defines how they appear.

function Header() {

    {/* This section determines where the search and sign-in buttons appear based on screen width. If Mobile, they will appear in the nav menu. Else, they will display on the header bar. */}
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))

    function ShowExtra(props) {

        const isLarge = props.isLarge

        if (isLarge) {

            // return (

            //     <>

            //         <Search />

            //         <SignIn />

            //     </>
            // )

        }

    }

    return (
        <header>

            {/* Renders the Navigation component & Search/SignIn if conditions are met.*/}
            <nav id='header-nav'>

                <Navigation />

                <ShowExtra isLarge={matches}/>

            </nav>

            {/* Renders the Header Logo component*/}
            <HeaderLogo />

        </header>
    )
}

export default Header