import { React, useState } from 'react'
import { Collapse, Button, Drawer, List, ListItemButton, ListItemText, ListItemIcon } from '@mui/material'
import SignIn from './SignIn'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MenuIcon from '@mui/icons-material/Menu'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

// This file defines the navigation menu and all links.

function Navigation() {

    // These useStates are defined, and are used for showing and hiding the Navigation menu and submenu.
    const [isDrawerOpen, setDrawerOpen] = useState(false)
    const [isServicesOpen, setServicesOpen] = useState(false)
    const [isLoggedIn, setLoggedIn] = useState(false)

    const handleClickServices = () => {
        setServicesOpen(!isServicesOpen);
    };

    // This section determines when the sign in button will appear in the Navigation menu based on screen width. If Mobile, it will appear. Else, it will not.
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))

    function ShowExtra(props) {

        const isLarge = props.isLarge

        if (!isLarge) {

            // return (

            //     <SignIn />

            // )
        }
    }

    function ShowDashboard(props) {

        const loggedIn = props.isLoggedIn

        if (loggedIn) {

            return (

                <ListItemButton onClick={() => window.location.href = '/dashboard'}>

                    <ListItemText disableTypography={true} primary='Dashboard' />

                </ListItemButton>

            )
        }
    }

    return (
        <>
            {/* Renders the Menu button component. If clicked, it will open the full Navigation menu. */}
            <Button onClick={() => setDrawerOpen(true)}>

                <MenuIcon class='nav-icon' />

            </Button>

            <Drawer anchor='left' open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>

                <List>

                    <ShowDashboard isLoggedIn={isLoggedIn} />

                    <ListItemButton sx={{
                        '&:hover': {
                            backgroundColor: '#e6eef5',
                        }
                    }} onClick={() => window.location.href = '/'}>

                        <ListItemIcon>

                            <HomeOutlinedIcon style={{ fontSize: '3rem', color: '#7740de' }} />

                        </ListItemIcon>

                        <ListItemText disableTypography={true} primary='Home' />

                    </ListItemButton>

                    <ListItemButton sx={{
                        '&:hover': {
                            backgroundColor: '#e6eef5',
                        }
                    }} onClick={() => window.location.href = '/our-team'}>

                        <ListItemIcon>

                            <Groups2OutlinedIcon style={{ fontSize: '3rem', color: '#f7408d' }} />

                        </ListItemIcon>

                        <ListItemText disableTypography={true} primary='Our Team' />

                    </ListItemButton>

                    <ListItemButton sx={{
                        '&:hover': {
                            backgroundColor: '#e6eef5',
                        }
                    }} onClick={handleClickServices}>

                        <ListItemIcon>

                            <BuildOutlinedIcon style={{ fontSize: '3rem', color: '#0153ec' }} />

                        </ListItemIcon>

                        <ListItemText disableTypography={true} primary='Services' />

                        {isServicesOpen ? <ExpandLess style={{ fontSize: '3rem' }} /> : <ExpandMore style={{ fontSize: '3rem' }} />}

                    </ListItemButton>

                    <Collapse in={isServicesOpen} timeout="auto" unmountOnExit>

                        <List component="div" disablePadding>

                            <ListItemButton sx={{
                                pl: 8, backgroundColor: '#fafafa', '&:hover': {
                                    backgroundColor: '#e6eef5',
                                }
                            }} onClick={() => window.location.href = '/telstra-services'}>

                                <ListItemIcon>

                                    <CallOutlinedIcon style={{ fontSize: '3rem', color: '#F96449' }} />

                                </ListItemIcon>

                                <ListItemText disableTypography={true} primary='Telstra' />

                            </ListItemButton>

                            <ListItemButton sx={{
                                pl: 8, backgroundColor: '#fafafa', '&:hover': {
                                    backgroundColor: '#e6eef5',
                                }
                            }} onClick={() => window.location.href = '/cisco-services'}>

                                <ListItemIcon>

                                    <LanOutlinedIcon style={{ fontSize: '3rem', color: '#049fd9' }} />

                                </ListItemIcon>

                                <ListItemText disableTypography={true} primary='Cisco' />

                            </ListItemButton>

                            <ListItemButton className='nav-button' sx={{
                                pl: 8, backgroundColor: '#fafafa', '&:hover': {
                                    backgroundColor: '#e6eef5'
                                }
                            }} onClick={() => window.location.href = '/microsoft-services'}>

                                <ListItemIcon>

                                    <DevicesOutlinedIcon style={{ fontSize: '3rem', color: '#ffba08' }} />

                                </ListItemIcon>

                                <ListItemText disableTypography={true} primary='Microsoft' />

                            </ListItemButton>

                            <ListItemButton sx={{
                                pl: 8, backgroundColor: '#fafafa', '&:hover': {
                                    backgroundColor: '#e6eef5',
                                }
                            }} onClick={() => window.location.href = '/navman-services'}>

                                <ListItemIcon>

                                    <NavigationOutlinedIcon style={{ fontSize: '3rem', color: '#99c221' }} />

                                </ListItemIcon>

                                <ListItemText disableTypography={true} primary='Navman' />

                            </ListItemButton>

                        </List>

                    </Collapse>

                    <ListItemButton sx={{
                        '&:hover': {
                            backgroundColor: '#e6eef5',
                        }
                    }} onClick={() => window.location.href = '/faq'}>

                        <ListItemIcon>

                            <QuestionAnswerOutlinedIcon style={{ fontSize: '3rem', color: '#ffa600' }} />

                        </ListItemIcon>

                        <ListItemText disableTypography={true} primary='FAQ' />

                    </ListItemButton>

                    <ListItemButton sx={{
                        '&:hover': {
                            backgroundColor: '#e6eef5',
                        }
                    }} onClick={() => window.location.href = '/contact-us'}>

                        <ListItemIcon>

                            <ContactPhoneOutlinedIcon style={{ fontSize: '3rem', color: '#00d0f8' }} />

                        </ListItemIcon>

                        <ListItemText disableTypography={true} primary='Contact Us' />

                    </ListItemButton>

                    <ListItemButton sx={{
                        '&:hover': {
                            backgroundColor: '#e6eef5',
                        }
                    }} onClick={() => window.location.href = '/privacy'}>

                        <ListItemIcon>

                            <LockOutlinedIcon style={{ fontSize: '3rem', color: '#15d461' }} />

                        </ListItemIcon>

                        <ListItemText disableTypography={true} primary='Privacy' />

                    </ListItemButton>

                    <ListItemButton sx={{
                        '&:hover': {
                            backgroundColor: '#e6eef5',
                        }
                    }} onClick={() => window.location.href = '/terms'}>

                        <ListItemIcon>

                            <ArticleOutlinedIcon style={{ fontSize: '3rem', color: '#f24a38' }} />

                        </ListItemIcon>

                        <ListItemText disableTypography={true} primary='Terms' />

                    </ListItemButton>

                    <ShowExtra isLarge={matches} />

                </List>



            </Drawer>
        </>
    )
}

export default Navigation