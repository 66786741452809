import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Dashboard() {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        fetch('http://localhost:3001/testimonials')
            .then(response => response.json())
            .then(data => setTestimonials(data))
            .catch(error => console.error('Error fetching testimonials:', error));
    }, []);

    return (
        <>
            <section id='dashboard-splash' className='section sub-splash'>
                <h1><span>Dashboard</span></h1>
            </section>

            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay
                autoPlaySpeed={10000}
                centerMode={true}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1200
                        },
                        items: 2,
                        partialVisibilityGutter: 40
                    },
                    laptop: {
                        breakpoint: {
                            max: 1199,
                            min: 900
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 899,
                            min: 600
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    mobile: {
                        breakpoint: {
                            max: 599,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {testimonials.map((testimonial, index) => (
                    <section key={index} className='testimonial'>
                        <figure className='customer-logo'>
                            <img src={testimonial.logo || ''} alt='' />
                        </figure>
                        <h2>{testimonial.title}</h2>
                        <h3>{testimonial.subtitle}</h3>
                        <p>{testimonial.content}</p>
                        <p>Website: <a href={testimonial.website}>{testimonial.website}</a></p>
                        <p>Phone: {testimonial.phone}</p>
                    </section>
                ))}
            </Carousel>
        </>
    );
}

export default Dashboard;
