import React from 'react'
import SiteTitle from '../Components/SiteTitle'

function Privacy() {
    SiteTitle("Bizcomm | Privacy")
    return (
        <>

            <section id='privacy-splash' class='section sub-splash'>

                <h1><span>Privacy</span></h1>

            </section>

            <section id='privacy-data' class='section'>

                <section class='grid-container'>

                    <h1>Privacy Policy</h1>
                    <h2>Definitions and Interpretation</h2>
                    <p>In these Conditions, the Rate Schedule and every Quote, Proposal, Order,
                        Plan contract, or other arrangement in connection with the supply of Goods or Services by Bizcomm Pty Ltd the following words have the following meanings:</p>


                    <p><strong>After Hours</strong> means any time outside of the ‘Support Coverage Hours’ in the ‘Agreement Summary’ section above. This will
                        vary depending on your selected agreement plan option;</p>

                    <p>The types of information we collect depends on how you use our products and services as well as the relationship we have with you as a customer.</p>

                    <p>This can include straightforward information like your name, date of birth, contact details
                        (including address, email address, phone number or mobile telephone number), occupation, driver's licence or passport number, Telstra PIN, username
                        or password and financial information (such as credit card or bank account numbers).</p>

                    <p>We may also collect more in-depth information including:</p>

                    <ul>
                        <li><strong>Financial and Credit Information</strong> related to your financial relationship with us, such as your income details, payment history,
                            your credit history and your service history. For more details, see the section on Credit Reporting below.</li>
                        <li><strong>Information about your products and services including</strong> device-specific information such as your hardware model, operating system version, unique device
                            and service identifiers, device status, serial numbers, settings, configuration and software and mobile network information.</li>
                        <li><strong>Information about how you use your products and services such as:</strong>
                            <ul>
                                <li>Your network usage including time and duration of your communications as well as information about the operation of the equipment,
                                    services and applications you use on our networks</li>
                                <li>How you use our services to access the internet, such as information about websites visited</li>
                                <li>Your location or the location of your devices when you are using our products and services</li>
                                <li>Information that allows us to identify you for verification purposes including, when you have given us permission to do so,
                                    biometric information like your fingerprints and voice patterns.</li>
                            </ul>
                        </li>
                        <li><strong>Technical Information about your products and services including</strong> details about our network performance including
                            information about how you use our networks.</li>




                    </ul>


                    <p>Sensitive information includes information about a person's race, ethnic origin, political opinions, health, religious or philosophical beliefs and criminal history.
                        We may collect some forms of sensitive information. For example, we may collect limited health information to provide priority assistance services or a Centrelink customer
                        reference number to provide you with a pensioner discount. We are subject to strict requirements in relation to sensitive information including to only collect and use
                        sensitive information with consent or otherwise in accordance with applicable law such as the Commonwealth Privacy Act 1988.</p>
                    <p>You might also need to provide personal information about other individuals to us (e.g. about your authorised representative). If so, we rely on you to have
                        informed those individuals that you are giving their personal information to us and to have advised them about this statement.</p>
                    <p>It is important to be aware that if you access another party’s website or application using one of our products, that other party will deal with your personal
                        information in accordance with its own privacy policy.</p>

                    <h2>How to we collect your information?</h2>

                    <p>There are three ways that we can collect your information.</p>

                    <ul>
                        <li><strong>You give it to us</strong> when you or your representative interacts with either us or one of our trusted partners. This might happen when you are
                            setting up an account with us or using one of our products or services.</li>
                        <li><strong>We collect information</strong> when you use our networks, products and services including our call centres and online services. For example, we may use network tools to
                            collect your call records, collect wireless device locations from our networks and your devices or use cookies and other technologies to identify your unique web browser.</li>
                        <li><strong>We obtain information from outside sources</strong> like credit reports, marketing mailing lists, and public information, (including public posts to social networking sites)
                            and commercially available personal, identity, geographic and demographic information. This can also include information gained from our partners if you have interacted with them.
                            These partners include our business and commercial partners, identity and fraud checking services, credit reporting bodies and wholesale and other customers.</li>
                    </ul>
                    <p>We understand that you might not want to give us particular personal information. If so, that may mean we are not able to provide you with the products or services you need.</p>

                    <h2>How do we keep your information?</h2>

                    <p>We may store your information in hard copy or electronic format and keep it in storage facilities that we own and operate ourselves, or that are owned and operated by our service providers.</p>

                    <p>We use a combination of technical solutions, security controls and internal processes to help us protect your information and our network from unauthorised access and disclosure.</p>

                    <p>We endeavour to ensure that personal information is kept as current as possible and that irrelevant or excessive data is deleted or made anonymous as soon as reasonably practicable.
                        However, some personal information may be retained for varying time periods in order to comply with legal and regulatory obligations and for other legitimate business reasons.</p>

                    <h2>How do we use your information?</h2>

                    <p>We will only use your information if we have a lawful reason to do so such as when it’s our legal duty or we have your consent and when it’s in our legitimate interest to
                        do so. These include:</p>

                    <p><strong>Administration</strong> - To help us properly manage the products and services we provide to you and to maintain and update our records. For example, we need to be able
                        to verify your identity to detect, prevent and address fraud. We also use your information for charging and billing and to identify breaches of our terms and conditions of
                        service.</p>

                    <p><strong>Network, security and fraud protection</strong> - As part of our network protection activities, we monitor Domain Name Servers (DNS) for known malicious domains which can, amongst other
                        things, lead to the downloading of malicious software on to devices. Where Telstra’s DNS services are used to connect to these known malicious domains, we may identify impacted
                        customers for the purposes of notifying them so they can take action to protect themselves.</p>

                    <p><strong>Communication</strong> - We need to be able to communicate with you in order to provide you with our products and services. We might do this on mediums such as email, SMS,
                        social media, search engines and web pages you may visit.</p>

                    <p><strong>Improvement</strong> – We are constantly working to not only maintain and improve our products, services and processes but to develop new ones. We use information we hold to help us
                        do this in several ways. For example, to monitor network use, quality and performance, and to operate, maintain, develop, test and upgrade our systems and infrastructure. We may also combine
                        information from one service with information from one of our partners’ services to improve our credit assessment and debt recovery processes.</p>

                    <p><strong>Development and analysis</strong> - It’s important we understand your information and communication needs. One of the ways we do this is through using analysis and business intelligence
                        techniques. This gives us high level insights into things like usage patterns, network performance, demographic trends and other types of behavioural data. In many cases this information is
                        aggregated and de-identified when analysed. We may share these anonymised insights with select business and commercial partners. In some cases, we may create insights with your information
                        on an identified basis but would only do so in compliance with privacy laws (such as, with your consent).</p>

                    <p><strong>Direct marketing</strong> - We want to make sure that you know about all our products, services and special offers that are relevant and are of interest to you. We may use the information
                        we hold to market and promote them directly to you. This also may include products, services and offers provided by our trusted partners. In some cases, this marketing activity can
                        continue after you have stopped using our products or services, unless you opt-out. You can stop us using your information for direct marketing by updating your preferences by
                        contacting us directly.</p>

                    <p><strong>Compliance</strong> - There are several circumstances where we are required or authorised by law to collect, use or disclose information. These include:</p>

                    <ul>
                        <li>as required or authorised by legislation (for example under the Telecommunications Act 1997 (Cth) and the Telecommunications (Interception and Access)
                            Act 1979 (Cth) including the data retention provisions)</li>
                        <li>to produce and distribute an alphabetical public number directory (known as the White Pages) in compliance with our Carrier Licence Conditions</li>
                        <li>as required by or in accordance with any industry code or industry standard registered under the Telecommunications Act 1997 (Cth)</li>
                        <li>when we need to undertake identity checks for pre-paid public mobile telecommunications services under the Telecommunications (Service Provider-Identity
                            Checks for Prepaid Mobile Carriage Services) Determination 2013</li>
                        <li>providing emergency call service centres and the relevant emergency services organisation(s), the most precise mobile location information we can access about
                            the device from which a call is made to the emergency call service number (000 and 112) at the time of the call</li>
                        <li>providing information in relation to calls to the emergency call service number (000 and 112) or to prevent or lessen threats to a person’s life or health under the
                            Telecommunications Act 1997, Telstra’s Carrier Licence Conditions and the Telecommunications (Emergency Call Service) Determination 2009.</li>
                    </ul>

                    <h2>When do we share your information?</h2>

                    <p>We may share your information with other parties who provide services to us, including organisations, agents, partners and contractors that assist us with providing our business
                        processes and products and services. These services include:</p>

                    <ul>
                        <li>providing, managing or administering your product or service including customer enquiries and support services</li>
                        <li>installation, maintenance and repair services</li>
                        <li>mailing operations, billing and debt-recovery functions</li>
                        <li>information technology and network services</li>
                        <li>to develop our credit assessment and credit worthiness rating system</li>
                        <li>fraud, crime or misconduct identification, investigation and prevention services</li>
                        <li>market research, marketing, telemarketing and door-knocking services</li>
                        <li>development, analysis and business intelligence functions.</li>
                    </ul>

                    <h2>We may also disclose your information to:</h2>

                    <ul>
                        <li>your authorised representatives or advisers</li>
                        <li>other parties when you ask us to do so or when you consent to that disclosure for the purposes of fraud,
                            crime or misconduct identification, investigation and prevention services</li>
                        <li>credit-reporting bodies (for more information see the Credit Reporting section below) for identity checking and credit related purposes such as creditworthiness, credit rating,
                            default listing, credit provision and financing. These include:</li>
                        <ul>
                            <li>Equifax Australian Group Pty Ltd (formerly known as Veda) Telephone: 138332 Address: Equifax Australian Group Pty Ltd PO Box 964 North Sydney NSW 2059 Email:
                                <a>Membership.query@veda.com.au</a> Website: <a>www.mycreditfile.com.au/</a></li>
                            <li>Illion Australia (formerly trading as Dun and Bradstreet (Australia) Pty Ltd) Telephone: 1300 734 806 Email: <a>PACAustral@dnb.com.au</a>
                                Website: <a>www.checkyourcredit.com.au/</a></li>
                            <li>Experian Australia Credit Services Pty Ltd Telephone: 1300 784 134 Address: Experian Australia Credit Services Pty Ltd PO Box 1969 North
                                Sydney NSW 2060 Website: <a>www.experian.com.au</a></li>
                        </ul>
                        <li>our contractors, our related entities or our business or commercial partners and other businesses we work with</li>
                        <li>other telecommunication and information service providers or to our wholesale and other customers from or through whom you may acquire products or services</li>
                        <li>the manager of the Integrated Public Number Database (IPND), and other organisations as required or authorised by law
                            (please see <a>www.acma.gov.au</a> for more information)</li>
                        <li>parties that assist us with fraud and identity checking including financial institutions and the Government’s National Document Verification Service, to verify the validity
                            of any Government issued documentation you provide as proof of identity ie: to check a Drivers Licence, Medicare, Passport etc.</li>
                        <li>law enforcement and national security agencies, and other government and regulatory authorities as required or authorised by law</li>
                        <li>other parties who assist us in managing or developing our business and corporate strategies and functions, including our corporate risk or funding functions</li>
                        <li>financiers, investors or other participants and parties (such as service providers and ratings agencies) and advisers involved in any sale of our debts, securitisation
                            or other financing arrangement (for example, a sale of amounts payable by you to Bizcomm)</li>
                        <li>our contractor, Sensis, to enable us to meet our Carrier Licence Conditions to produce and distribute an alphabetical public number directory (known as the White Pages).
                            Note, if you have requested a silent line number your number will not be published in the White Pages.</li>
                        <li>other parties as required by or in accordance with any industry code or industry standard registered under the Telecommunications Act 1997 (Cth)</li>
                        <li>government agencies for purposes associated with connecting new services to the National Broadband Network</li>
                        <li>and for the purposes of facilitating or implementing a transfer/sale of all or part of our assets or business.</li>
                    </ul>

                    <p>In some cases, the organisations that we may disclose your information to may be based outside the location where the information is collected. For example, we may share
                        your information with other parties in Australia, Canada, Chile, China, Hong Kong, countries within the European Union, India, Japan, Malaysia, New Zealand, Philippines,
                        Romania, Russia, Singapore, South Africa, South Korea, Sri Lanka, Taiwan, the UAE, Ukraine, the United States of America and Vietnam.</p>

                    <p>Where we do this, we require these parties to take appropriate measures to protect that information and to restrict how they can use that information.</p>

                    <h2>Credit reporting</h2>

                    <p>Sometimes, such as when we are checking your credit worthiness or assessing your credit situation, we might collect credit information from or give information to
                        credit reporting bodies. Credit information can include:</p>

                    <ul>
                        <li>identification information</li>
                        <li>details about information requests made about you to credit reporting bodies</li>
                        <li>current and historical details about credit applications you have made and credit arrangements you have entered</li>
                        <li>information about overdue payments, default listings and about serious credit infringements and information about payments
                            or subsequent arrangements in relation to either of these</li>
                        <li>various publicly available information like bankruptcy and credit-related court judgments</li>
                        <li>credit scores or risk assessments indicating an assessment of your credit worthiness.</li>
                    </ul>

                    <p>Credit information relates primarily to your dealings with other credit providers (for example, banks, other financial institutions, or other organisations that
                        may provide you with credit in connection with their products or services). It may also include certain credit worthiness information that we derive from the data
                        that we receive from a credit reporting body. Sometimes we may collect this information about you from other credit providers.</p>

                    <p>We may disclose your credit information to credit reporting bodies. They in turn may include it in credit reporting information they provide to other credit
                        providers to assist them to assess your credit worthiness.</p>

                    <p>We may use or disclose your credit information for purposes such as:</p>

                    <ul>
                        <li>developing our credit assessment and credit worthiness rating system</li>
                        <li>processing credit-related applications and managing credit that we provide</li>
                        <li>assisting you to avoid defaults</li>
                        <li>collecting amounts, you may owe us in relation to such credit and dealing with serious credit infringements</li>
                        <li>assigning our debts or acting in connection with any securitisation or other financing arrangement</li>
                        <li>participating in the credit reporting system</li>
                        <li>dealing with complaints or regulatory matters relating to credit or credit reporting</li>
                        <li>when required or authorised by another law</li>
                        <li>those purposes under “How do we use your information?” and “When do we share your information?” above except in relation to information
                            we collect from credit reporting bodies.</li>
                    </ul>

                    <p>You have the right to request credit reporting bodies not to:</p>

                    <ul>
                        <li>use your credit eligibility information to determine your eligibility to receive direct marketing from credit providers; and</li>
                        <li>use or disclose your credit eligibility information if you have been or are likely to be a victim of fraud.</li>
                    </ul>

                    <h2>How can you access or correct your personal information?</h2>

                    <p>To ensure that we can provide you with the best products and services possible, it’s important that you make sure the personal
                        information we hold about you is accurate, up-to-date and complete. If any of your details change, you may contact us using the contact details on
                        our website so that we can consider and respond to your request. You also have the right to request a copy of your information that we hold about you.
                        There is no charge to submit a request or to correct information.</p>

                    <h2>How can you make a privacy complaint?</h2>

                    <p>You can also use our contact details to notify us of any privacy complaint you have against us. We are committed to acknowledging your
                        complaint in a prompt manner and will give you an estimated timeframe for when we will respond to your complaint.</p>
                    <p>If your complaint is in relation to a credit reporting issue, we will acknowledge your complaint in writing as soon as practicable
                        within 7 days. We will aim to investigate and resolve your complaint within 30 days of receiving it. If we need more time, we will notify you about
                        the reasons for the delay and indicate a new estimate time frame. We may need to consult with a credit reporting body or another credit provider
                        to investigate your complaint.</p>
                    <p>While we hope that we will be able to resolve any complaints you may have without needing to involve third parties, you may also be able to lodge
                        a complaint with a relevant regulator such as the <a href="https://www.oaic.gov.au/">Australian Information Commissioner</a> or
                        the <a href="https://www.tio.com.au/">Telecommunications Industry Ombudsman</a>.</p>

                </section>

            </section>

        </>
    )
}

export default Privacy