import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SiteTitle from '../Components/SiteTitle';
import NavmanFig1 from '../Images/Navman Figure 1.webp'
import NavmanIcon1 from '../Images/Navman Icon 1.webp'
import NavmanIcon2 from '../Images/Navman Icon 2.webp'
import NavmanIcon3 from '../Images/Navman Icon 3.webp'
import NavmanIcon4 from '../Images/Navman Icon 4.webp'

function NavmanServices() {
    SiteTitle('Bizcomm | Navman Services')
    return (
        <>

            <section id='navman-splash' class='section sub-splash'>

                <h1><span>Navman Services</span></h1>

            </section>

            <ThemeProvider
                theme={createTheme({
                    breakpoints: {
                        values: {
                            desktop: 1200,
                            laptop: 900,
                            tablet: 600,
                            mobile: 0,
                        },
                    },
                })}
            >

                <section id='navman-content-1' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={10}>

                            <Grid display='flex' justifyContent='center' mobile={10} tablet={10} laptop={6} desktop={6}>

                                <h1 class='content-h1' style={{ margin: 'auto' }}>Navman - GPS Fleet Tracking Systems</h1>

                            </Grid>

                            <Grid mobile={10} tablet={10} laptop={4} desktop={4}>

                                <div id='navman-services-logo' />

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='navman-content-2' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={10}>

                            <Grid display='flex' justifyContent='center' flexDirection='column' mobile={10} tablet={7} laptop={7} desktop={7}>

                                <p class='content-p'>Track, measure and optimise vehicles, equipment, light assets and revolutionize your business with fleet management software.</p>
                                <p class='content-p'>A GPS tracking solution is an ideal solution for enterprises and SME&rsquo;s with valuable assets. It allows users to track these assets, such as important vehicles or standalone valuables.</p>
                                <p class='content-p'>Teletrac Navman has scalable and customisable solutions suitable for businesses of all sizes and from a wide range of industries. The Teletrac Navman system can be integrated into existing businesses applications via an API (note this is a non-standard solution and requires Product Management assistance) and they have a satellite communications solution for business operating in remote areas.</p>
                                <p class='content-p'>By using a GPS tracking solution from Teletrac Navman, customers are able increase productivity and reduce operating costs because they can better manage their fleet &amp; assets. In addition, solution will help customers save money on fuel, improve customer service and better manage fleet maintenance.</p>

                            </Grid>

                            <Grid display='flex' justifyContent='center' mobile={10} tablet={3} laptop={3} desktop={3}>

                                <img class='content-img' src={NavmanFig1} alt='' style={{ margin: 'auto' }} />

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='navman-content-3' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={4}>

                            <Grid mobile={2} tablet={2} laptop={1} desktop={1}>

                                <img class='content-img' src={NavmanIcon1} alt='' />

                                <h3 class='content-h3 navman'>Reduce Fuel Consumption</h3>

                                <p class='content-p'>Manage idling and track fuel consumption across your fleet</p>

                            </Grid>

                            <Grid mobile={2} tablet={2} laptop={1} desktop={1}>

                                <img class='content-img' src={NavmanIcon2} alt='' />

                                <h3 class='content-h3 navman'>EWD Compliance</h3>

                                <p class='content-p'>Stay compliant, avoid violations and optimise driver hours</p>

                            </Grid>

                            <Grid mobile={2} tablet={2} laptop={1} desktop={1}>

                                <img class='content-img' src={NavmanIcon3} alt='' />

                                <h3 class='content-h3 navman'>Increase Efficiency</h3>

                                <p class='content-p'>Understand operational performance and identify areas to improve</p>

                            </Grid>

                            <Grid mobile={2} tablet={2} laptop={1} desktop={1}>

                                <img class='content-img' src={NavmanIcon4} alt='' />

                                <h3 class='content-h3 navman'>Improve Safety</h3>

                                <p class='content-p'>Use the latest video technology to create a culture of safety</p>

                            </Grid>



                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <p class='content-p'>Contact us today for more information or a demo on how this solution can benefit your business.</p>

                            </Grid>

                        </Grid>

                    </section>

                </section>

            </ThemeProvider>

        </>
    )
}

export default NavmanServices