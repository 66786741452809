import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SiteTitle from '../Components/SiteTitle';
import figure1 from '../Images/Cisco - Figure 1.svg'
import figure2 from '../Images/Cisco - Figure 2.svg'
import figure3 from '../Images/Cisco - Figure 3.svg'
import figure4 from '../Images/Cisco - Figure 4.svg'

function CiscoServices() {
    SiteTitle("Bizcomm | Cisco Services")
    return (
        <>

            <section id='cisco-splash' class='section sub-splash'>

                <h1><span>Cisco Services</span></h1>

            </section>

            <ThemeProvider
                theme={createTheme({
                    breakpoints: {
                        values: {
                            desktop: 1200,
                            laptop: 900,
                            tablet: 600,
                            mobile: 0,
                        },
                    },
                })}
            >

                <section id='cisco-intro' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={4}>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <h1 class='content-h1'>Cisco Meraki</h1>
                                <p class='content-p'>Our network engineers are Cisco certified and have many years&rsquo; experiences in supporting Business and Corporate network consisting of Cisco, Meraki Devices. Bizcomm Field Engineers can carry out site audits/surveys to design, propose, deliver, and support on the latest tech solution to cover requirements such as Wi-Fi network (Wireless LAN), Network Security, and SD WAN offerings.</p>
                                <p class='content-p'>The Cisco Meraki MX is a multifunctional security and SD-WAN enterprise appliance with a wide set of capabilities to address multiple use cases&mdash;from an all-in-one device. Organizations of all sizes and across all industries rely on the MX to deliver secure connectivity to hub locations or multi-cloud environments, as well as application quality of experience (QoE), through advanced analytics with machine learning.</p>
                                <p class='content-p'>The MX is 100% cloud managed, so installation and remote management is truly zero touch, making it ideal for distributed branches, and data centre locations. Natively integrated with a comprehensive suite of secure network and assurance capabilities, the MX eliminates the need for multiple appliances. These capabilities include application-based firewalling, content filtering, web search filtering, SNORT&reg;-based intrusion detection and prevention, Cisco Advanced Malware Protection (AMP), site-to-site Auto VPN, client VPN, WAN and cellular failover, dynamic path selection, web application health, VoIP health, and more.</p>
                                <p class='content-p'>SD-WAN can easily be extended to deliver optimized access to resources in public and private cloud environments with virtual MX (vMX) appliances. Public clouds supported with Mx include Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform, and Alibaba Cloud, and private cloud support through Cisco NFVIS.</p>

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='cisco-content-1' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={4}>

                            <Grid mobile={4} tablet={2} laptop={2} desktop={2}>

                                <div id='cisco-services-logo' />

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={2} desktop={2}>

                                <h3 class='content-h3'>Advanced quality of experience (QoE) analytics</h3>
                                <ul>
                                    <li><p class='content-p'>Monitor end-to-end health of web applications at-a-glance across the LAN, WAN, and application server</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Machine-learned smart application thresholds autonomously apply to identify true anomalies based on past behavioral patterns</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Monitor the health of all MX WAN links, including cellular, across your entire organization</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Get detailed hop-by-hop VoIP performance analysis across all uplinks</p></li>
                                </ul>

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={2} desktop={2}>

                                <h3 class='content-h3'>Agile on-premises and cloud security capabilities informed by Cisco Talos</h3>
                                <ul>
                                    <li><p class='content-p'>Next-gen layer 7 firewall for identity-based security policies and application management</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Advanced Malware Protection with sandboxing; file reputation-based protection engine powered by Cisco AMP</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Intrusion prevention: PCI-compliant IPS sensor using industry-leading SNORT&reg; signature database from Cisco</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Granular and automatically updated category-based content filtering</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Other capabilities: SSL decryption/inspection, data loss prevention (DLP), cloud access security broker (CASB), SaaS tenant restrictions, granular app control, file type control</p></li>
                                </ul>

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={2} desktop={2}>

                                <div id='cisco-fig-1' class='content-img' />

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='cisco-content-2' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={4}>

                            <Grid mobile={4} tablet={2} laptop={2} desktop={2}>

                                <div id='cisco-fig-2' class='content-img' />

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={2} desktop={2}>

                                <h3 class='content-h3'>Branch gateway services</h3>
                                <ul>
                                    <li><p class='content-p'>Built-in DHCP, NAT, QoS, and VLAN management services</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Web caching: accelerates frequently accessed content</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Load balancing: combines multiple WAN links into a single high-speed interface, with policies for QoS, traffic shaping, and failover</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Smart connection monitoring provides automatic detection of layer 2 and layer 3 outages and fast failover, including the option of integrated LTE Advanced or 3G/4G modems</p></li>
                                </ul>

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={2} desktop={2}>

                                <h3 class='content-h3'>Industry-leading cloud management</h3>
                                <ul>
                                    <li><p class='content-p'>Unified firewall, switching, wireless LAN, and mobile device management through an intuitive web-based dashboard</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Template-based settings scale easily from small deployments to tens of thousands of devices</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Role-based administration, configurable email alerts for a variety of important events, and easily auditable change logs</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Summary reports with user, device, and application usage details archived in the cloud</p></li>
                                </ul>

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={2} desktop={2}>

                                <div id='cisco-fig-3' class='content-img' />

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='cisco-content-3' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={4}>

                            <Grid mobile={4} tablet={2} laptop={2} desktop={2}>

                                <div id='cisco-fig-4' class='content-img' />

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={2} desktop={2}>

                                <h3 class='content-h3'>Intelligent site-to-site VPN with Cisco SD-WAN powered by Meraki</h3>
                                <ul>
                                    <li><p class='content-p'>Auto VPN allows automatic VPN route generation using IKE/IKEv2/IPsec setup; runs on physical MX appliances and as a virtual instance in public and private clouds</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>SD-WAN with active/active VPN, policy-based routing, dynamic VPN path selection, and support for application-layer performance profiles to ensure prioritization of applications types that matter</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Interoperates with all IPsec VPN devices and services</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Automated MPLS to VPN failover within seconds of a connection failure</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Layer2 TP IPsec remote client VPN included at no extra cost with support for native Windows, Mac OS X, iPad, and Android clients</p></li>
                                </ul>
                                <ul>
                                    <li><p class='content-p'>Support for Cisco AnyConnect remote client VPN (AnyConnect license required)</p></li>
                                </ul>

                            </Grid>

                        </Grid>

                    </section>

                </section>

            </ThemeProvider>

        </>
    )
}

export default CiscoServices