import React from 'react'
import BizcommLogo from '../Images/Bizcomm Banner - Transparent.svg'

function Footer() {
    return (
        <footer>

            <section id='footer-container' class='grid-container'>

                <img src={BizcommLogo} alt='Bizcomm Logo' />

                <p class='content-p'>Bizcomm Pty Ltd © 2024</p>

                <p class='content-p'>

                    <a class='footer-link' href='/contact-us'>Contact Us</a>
                    {' | '}
                    <a class='footer-link' href='/privacy'>Privacy Policy</a>
                    {' | '}
                    <a class='footer-link' href='/terms'>Terms</a>
                    {' | '}
                    <a class='footer-link' href='https://www.facebook.com/BizcommTeam/?locale=mt_MT' target='_blank' rel='noreferrer'> Facebook</a>
                    {' | '}
                    <a class='footer-link' href='https://www.linkedin.com/company/bizcomm-au/about/' target='_blank' rel='noreferrer'>LinkedIn</a>
                    {' | '}
                    <a class='footer-link' href='/'>Sign In</a>

                </p>

            </section>

        </footer >
    )
}

export default Footer