import React from 'react'
import ApologiesImg from '../Images/Sticker image, Apologies_.png'

function NotFound() {
    return (
        <>

            <section id='not-found-splash' class='section sub-splash'>

                <h1>404 - Page not found</h1>

            </section>

            <section id='oops-content' class='section'>

                    <section class='grid-container' style={{display:'flex', justifyContent:'center'}}>

                        <img src={ApologiesImg} alt='Apologies for the inconvenience'/>

                    </section>

                </section>

        </>
    )
}

export default NotFound