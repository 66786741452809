import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import SiteTitle from '../Components/SiteTitle'
import MicrosoftFig1 from '../Images/Microsoft Figure 1.png'
import VerifiedIcon from '@mui/icons-material/Verified'
import PublicIcon from '@mui/icons-material/Public'
import GroupsIcon from '@mui/icons-material/Groups'
import DevicesIcon from '@mui/icons-material/Devices'
import MicrosoftFig2 from '../Images/Microsoft Figure 2.png'

function MicrosoftServices() {
    SiteTitle("Bizcomm | Microsoft Services")

    const iconStyling = { fontSize: '5rem', color: '#3f51bf' }

    return (
        <>

            <section id='microsoft-splash' class='section sub-splash'>

                <h1><span>Microsoft Services</span></h1>

            </section>

            <ThemeProvider
                theme={createTheme({
                    breakpoints: {
                        values: {
                            desktop: 1200,
                            laptop: 900,
                            tablet: 600,
                            mobile: 0,
                        },
                    },
                })}
            >

                <section id='microsoft-content-1' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={4}>

                            <Grid mobile={4} tablet={3} laptop={3} desktop={3}>

                                <h1 class='content-h1'>Microsoft</h1>
                                <h2 class='content-h2'>Hosting and Cloud Services</h2>
                                <p class='content-p'>Whether it's migrating to Microsoft 365 hosted services, or if your business is just looking to gradually move to the cloud, our team can review your requirements, propose the solution and implement this in conjunction with the Telstra Team, to ensure the project is delivered to scope on time and on budget.</p>
                                <p class='content-p'>As technology changes, our Team is here dedicated to assist your business with its cloud or hosting migration plans. If you are already using and paying for Microsoft through another provider, you can still consider moving these over to Telstra billing. Telstra offer a rebate via their Telstra Enterprise Funds to help with any optimisation initiatives. <br /><br /> Our Team members are Microsoft certified to ensure the solutions we propose and provide are best of class and we deliver can on your business requirements</p>

                            </Grid>

                            <Grid mobile={4} tablet={1} laptop={1} desktop={1}>

                                <div id='microsoft-services-logo' />

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='microsoft-content-2' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={4}>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <h2 class='content-h2'>Teams Calling with Operator Connect with Telstra</h2>
                                <p class='content-p'>Microsoft Operator Connect with Telstra enables cloud-based calling from Microsoft Teams and is delivered through the Adaptative Collaboration self-service management portal.</p>
                                <p class='content-p'>Plan a successful move to Microsoft Operator Connect</p>
                                <p class='content-p'>We have a range of pre-determined services packages that can help you plan, deploy, deliver and run your solution as you continue your journey to the cloud through MS Operator Connect.</p>
                                <p class='content-p'>Migrate and rollback with ease</p>
                                <p class='content-p'>Become more resilient to outages - Ensure there is an alternative destination phone number if incoming calls fail.</p>
                                <p class='content-p'>Microsoft Operator Connect from Telstra is designed to take the complexity out of managing a dedicated access network for voice calling services to the PSTN. The connectivity to the PSTN is provided by Telstra directly into Microsoft 365 and is provided with a fully redundant and managed architecture. Telstra and Microsoft will peer physically in Australian via redundant locations. There are no PSTN interconnect infrastructure considerations for customers to support or manage.</p>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <img id='microsoft-fig-1' class='content-img' src={MicrosoftFig1} alt='Microsoft - Telstra relationship diagram.' />

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <p class='content-p'>For more information, visit the link <a href='https://www.telstra.com.au/help/critical-information-summaries/business/business-telephony/telstra-adaptive-collaboration/microsoft-operator-connect-with-telstra' target='blank_?' rel="noreferrer">here</a>.</p>

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='microsoft-content-3' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={4}>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <h2 class='content-h2'>Why Bizcomm and Telstra?</h2>

                                <p class='content-p'>Our unique capabilities, Microsoft relationship and status as Australia&rsquo;s largest network provider. Why look anywhere else for the best calling and collaboration solutions?</p>

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={1} desktop={1}>

                                <VerifiedIcon sx={iconStyling} />

                                <h3 class='content-h3'>Proven solutions and experience</h3>

                                <p class='content-p'>We bring expertise and learning from many satisfied Telstra Calling customers into Operator Connect.</p>

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={1} desktop={1}>

                                <PublicIcon sx={iconStyling} />

                                <h3 class='content-h3'>Trusted, local end-to-end capabilities</h3>

                                <p class='content-p'>We&rsquo;ve long been recognised as Australia&rsquo;s trusted sovereign calling partner with end-to-end capabilities.</p>

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={1} desktop={1}>

                                <GroupsIcon sx={iconStyling} />

                                <h3 class='content-h3'>Unique Microsoft relationship</h3>

                                <p class='content-p'>Working together on Teams calling solutions for many years, our close knowledge sharing, technology alignment and shared focus on ongoing co-innovation for clients ensures you&rsquo;re getting the most out of Operator Connect now, and into the future.</p>

                            </Grid>

                            <Grid mobile={4} tablet={2} laptop={1} desktop={1}>

                                <DevicesIcon sx={iconStyling} />

                                <h3 class='content-h3'>Flexible calling options</h3>

                                <p class='content-p'>We offer the flexibility to mix and match individual and shared calling plans and provide support for analogue and SIP devices.</p>

                            </Grid>

                        </Grid>

                    </section>

                </section>

                <section id='microsoft-content-4' class='section'>

                    <section class='grid-container'>

                        <Grid container rowSpacing={4} columnSpacing={8} columns={10}>

                            <Grid mobile={10} tablet={10} laptop={10} desktop={10}>

                                <h2 class='content-h2'>Microsoft 365</h2>

                                <h3 class='content-h3' style={{ margin: 0 }}>The best tools. Your best team. The world at your fingertips.</h3>

                                <p class='content-p'>Microsoft 365 empowers your employees to do their best work from anywhere in the world, together in one place</p>

                            </Grid>

                            <Grid mobile={10} tablet={10} laptop={10} desktop={10}>

                                <img id='microsoft-fig-2' class='content-img' src={MicrosoftFig2} alt='Microsoft 365 Splash' />

                            </Grid>

                            <Grid mobile={5} tablet={5} laptop={2} desktop={2}>

                                <h3 class='content-h3' >Create anywhere, anytime, with any app</h3>

                                <p class='content-p'>Anyone in your organisation can quickly create documents, presentations, and worksheets within a single, unified, Microsoft 365 experience</p>

                            </Grid>

                            <Grid mobile={5} tablet={5} laptop={2} desktop={2}>

                                <h3 class='content-h3' >Your content<br />Your Microsoft 365</h3>

                                <p class='content-p'>Microsoft 365 empowers your organisation to organise, and safely store files in OneDrive with intuitive and easy organisational tools</p>

                            </Grid>

                            <Grid mobile={5} tablet={5} laptop={2} desktop={2}>

                                <h3 class='content-h3' >Work together, better</h3>

                                <p class='content-p'>Keep your business connected from anywhere with chat and cloud collaboration tools</p>

                            </Grid>

                            <Grid mobile={5} tablet={5} laptop={2} desktop={2}>

                                <h3 class='content-h3' >Pick up where you left off</h3>

                                <p class='content-p'>Microsoft 365 seamlessly tracks updates, task, and comments across all your files so you can pick up right where you left off</p>

                            </Grid>

                            <Grid mobile={5} tablet={5} laptop={2} desktop={2}>

                                <h3 class='content-h3' >More apps in fewer places</h3>

                                <p class='content-p'>The new Microsoft 365 brings together your favourite Microsoft apps all in one, intuitive platform</p>

                            </Grid>

                            <Grid mobile={10} tablet={10} laptop={10} desktop={10}>

                                <p class='content-p'>For more information, visit the link <a href='https://www.office.com/' target='blank_?' rel="noreferrer">here</a>.</p>

                            </Grid>

                        </Grid>

                    </section>

                </section>

            </ThemeProvider >

        </>
    )
}

export default MicrosoftServices