import React from 'react'
import ReactDOM from 'react-dom/client'
import './Styles/index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'

// This file is used for rendering the web-app, and enabling routing between pages.

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
)