import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css';
import WynstanLogo from '../Images/Customer - Wynstan.svg'
import BlairsLogo from '../Images/Customer - Blairs Tyres.png'
import RealDairyLogo from '../Images/Customer - Real Dairy Australia.png'
import StructialLogo from '../Images/Customer - Structial.png'

function Testimonials() {
    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={10000}
            centerMode={true}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1200
                    },
                    items: 2,
                    partialVisibilityGutter: 40
                },
                laptop: {
                    breakpoint: {
                        max: 1199,
                        min: 900
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                },
                tablet: {
                    breakpoint: {
                        max: 899,
                        min: 600
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                },
                mobile: {
                    breakpoint: {
                        max: 599,
                        min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >

            <section class='testimonial'>

                <figure class='customer-logo'>
                    <img src={WynstanLogo} alt='Wynstan' />
                </figure>

                <h2>Safeguard Home Improvements</h2>

                <h3>Daniel Oaten - IT Manager</h3>

                <p>"Bizcomm have transformed our Telstra services, saving us a considerable amount of money, have set up redundancies to reduce downtime, and ensure our 400+ staff have the tools they need to be able to do their jobs well.<br />

                    Thanh, in particular, is one-of-a-kind - she has a rare ability to fully understand technical requirements and find the right solution for my business. I wish I'd met her earlier and look forward to working with her for many years to come."
                </p>

                <aside class='testimonial-contact-info'>

                    <p>Website: <a href='https://www.wynstan.com.au'>www.wynstan.com.au</a></p>

                    <p>Phone: 1300 667 679</p>

                </aside>

            </section>

            <section class='testimonial'>

                <figure id='blairs-logo' class='customer-logo'>
                    <img src={BlairsLogo} alt="Blair's Tyres" />
                </figure>

                <h2>Blair's Tyres</h2>

                <h3>Peter Fitzpatrick - Pomery Group CFO</h3>

                <p>Enjoy working with a committed team who provide quick response, honesty and always try to do the best for our business. Change management can sometimes lead to teething problems, but the team is quick to respond and address any issues. Phones and internet are at the core of our business communications, and the Bizcomm team can provide consistent service with integrity and reliability.</p>

                <aside class='testimonial-contact-info'>

                    <p>Website: <a href='https://www.blairstyres.com.au'>www.blairstyres.com.au</a></p>

                    <p>Phone: (02) 8522 5700</p>

                </aside>

            </section>

            <section class='testimonial'>

                <figure class='customer-logo'>
                    <img src={RealDairyLogo} alt='Real Dairy Australia' />
                </figure>

                <h2>Real Dairy Australia</h2>

                <h3>David Fan - IT Manager</h3>

                <p>The Bizcomm Team is always available to help with any issues. Their technical understanding is incredible, and I rely on their recommendations to ensure our business has everything it needs from Telstra to run effectively.<br />
                    We’ve saved so much time and money by using Bizcomm and are feeling more confident about moving into new technology in the future with their help. We’ll continue to work with Thanh and her team for a long time to come.
                </p>

                <aside class='testimonial-contact-info'>

                    <p>Website: <a href='https://www.realdairy.com.au'>www.realdairy.com.au</a></p>

                    <p>Phone: 1800 686 686</p>

                </aside>

            </section>

            <section class='testimonial'>

                <figure class='customer-logo'>
                    <img src={StructialLogo} alt='Structial' />
                </figure>

                <h2>Structial</h2>

                <h3>Loukas Hatzigeorgiou - Estimator</h3>

                <p>The Bizcomm Team is second to none in the business, pro-active and always on top of our account ensuring our telecommunications operate effectively and providing value every day. Bizcomm couldn't be more highly recommended.</p>

                <aside class='testimonial-contact-info'>

                    <p>Website: <a href='https://structial.com.au'>structial.com.au</a></p>

                    <p>Phone: 1300 787 828</p>

                </aside>

            </section>

        </Carousel>
    )
}

export default Testimonials