import React from 'react'
import SiteTitle from '../Components/SiteTitle'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemeProvider, createTheme } from '@mui/material/styles';

function FAQ() {
    SiteTitle("Bizcomm | FAQ")
    return (
        <>

            <section id='faq-splash' class='section sub-splash'>

                <h1><span>Frequently Asked Questions</span></h1>

            </section>

            <ThemeProvider
                theme={createTheme({
                    breakpoints: {
                        values: {
                            desktop: 1200,
                            laptop: 900,
                            tablet: 600,
                            mobile: 0,
                        },
                    },
                })}
            >

                <section id='home-welcome' class='section'>

                    <section class='grid-container'>

                        <Grid container spacing={{ mobile: 1, tablet: 2, laptop: 3, desktop: 4 }} columns={4}>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <h1 class='content-h1'>Got Questions?</h1>

                                <h3 class='content-h3' style={{ marginTop: 0 }}>Visit the links below to check out some answers!</h3>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <section id='faq-telstra' class='faq-container'>

                                    <h2 class='content-h2 faq'>Telstra</h2>

                                    <p class='content-p'><a href='https://www.telstra.com.au/business-enterprise/support' target='_blank' rel='noreferrer'>Telstra Enterprise Support</a></p>

                                    <p class='content-p'><a href='https://www.telstra.com.au/help/critical-information-summaries#business' target='_blank' rel='noreferrer'>Telstra Business Critical Information Summary</a></p>

                                    <p class='content-p'><a href='https://www.telstra.com.au/business-enterprise/support/adaptive-mobility' target='_blank' rel='noreferrer'>Telstra Adaptive Mobility Support</a></p>

                                    <p class='content-p'><a href='https://www.telstra.com.au/help/critical-information-summaries/business/mobile/adaptive-mobility-solution/adaptive-mobility-plans' target='_blank' rel='noreferrer'>Telstra Adaptive Mobility - Critical Information Summary</a></p>

                                    <p class='content-p'><a href='https://www.telstra.com.au/business-enterprise/support/tipt' target='_blank' rel='noreferrer'>Telstra TIPT Support</a></p>

                                    <p class='content-p'><a href='https://www.telstra.com.au/business-enterprise/support/tipt-phones' target='_blank' rel='noreferrer'>Telstra TIPT Phones Guide</a></p>

                                </section>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <section id='faq-cisco' class='faq-container'>

                                    <h2 class='content-h2 faq'>Cisco</h2>

                                    <p class='content-p'><a href='https://documentation.meraki.com/' target='_blank' rel='noreferrer'>Cisco Meraki Knowledge Base</a></p>

                                    <p class='content-p'><a href='https://meraki.cisco.com/product-collateral/mx-family-datasheet/?file' target='_blank' rel='noreferrer'>Cisco Meraki MX Family Datasheet</a></p>

                                </section>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <section id='faq-microsoft' class='faq-container'>

                                    <h2 class='content-h2 faq'>Microsoft</h2>

                                    <p class='content-p'><a href='https://www.microsoft.com/en-au/microsoft-365/microsoft-365-for-home-and-school-faq' target='_blank' rel='noreferrer'>Microsoft 365 FAQ</a></p>

                                    <p class='content-p'><a href='https://www.telstra.com.au/business-enterprise/support/microsoft-operator-connect' target='_blank' rel='noreferrer'>Microsoft Operator Connect Support</a></p>

                                    <p class='content-p'><a href='https://learn.microsoft.com/en-us/azure/expressroute/expressroute-troubleshooting-expressroute-overview' target='_blank' rel='noreferrer'>Microsoft Azure - Verify ExpressRoute connectivity</a></p>

                                </section>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={2} desktop={2}>

                                <section id='faq-navman' class='faq-container'>

                                    <h2 class='content-h2 faq'>Navman</h2>

                                    <p class='content-p'><a href='https://www.teletracnavman.com.au/fleet-management-software/fleet-tracking/resources' target='_blank' rel='noreferrer'>Telectrac Navman Fleet Tracking Resources</a></p>

                                    <p class='content-p'><a href='https://transtech.atlassian.net/wiki/spaces/TKB/overview' target='_blank' rel='noreferrer'>Telectrac Navman Knowledge Base</a></p>

                                    <p class='content-p'><a href='https://www.telstra.com.au/help/critical-information-summaries/business/internet-of-things-and-connected-vehicles/teletrac-navman/telstra-business-teletrac-navman-gps-fleet-and-asset-management-solutions' target='_blank' rel='noreferrer'>Telectrac Navman - Telstra Critical Information Summary</a></p>

                                </section>

                            </Grid>

                            <Grid mobile={4} tablet={4} laptop={4} desktop={4}>

                                <section>

                                    <h3 class='content-h3' style={{margin:'auto'}}>Still have questions? <a href='/contact-us' style={{color:'#2744ff', textShadow:'none', fontWeight:700}}>Contact us</a> now!</h3>

                                </section>

                            </Grid>

                        </Grid>

                    </section>

                </section>

            </ThemeProvider>

        </>
    )
}

export default FAQ