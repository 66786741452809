import React from 'react'
import SiteTitle from '../Components/SiteTitle'

function Terms() {
    SiteTitle("Bizcomm | Terms")
    return (
        <>

            <section id='terms-splash' class='section sub-splash'>

                <h1><span>Terms</span></h1>

            </section>

            <section id='terms-data' class='section'>

                <section class='grid-container'>

                    <h1>Terms and Conditions</h1>

                    <h2>Definitions and Interpretation</h2>

                    <p>In these Conditions, the Rate Schedule and every Quote, Proposal, Order, Plan contract, or other arrangement in connection with the supply of Goods or Services
                        by Bizcomm Pty Ltd the following words have the following meanings:</p>

                    <p><strong>After Hours </strong>means any time outside of the ‘Support Coverage Hours’ in the ‘Agreement Summary’ section above. This will vary depending on your
                        selected agreement plan option;</p>

                    <p><strong>Business Hours</strong> means the days and times defined in ‘Support Coverage Hours’ in the ‘Agreement Summary’ section above. This will vary
                        depending on your selected agreement plan option;</p>

                    <p><strong>Client, You or Your </strong> means a person or business entity who seeks or obtains a quote for, or who orders, Goods or Services from
                        Bizcomm Pty Ltd, and includes both a person whose name is on the Order, Proposal, or on an email attached to which is an order, a person who places
                        an order, and a person on whose behalf an Order is placed or on whose behalf it appears an order is placed, and in any case each of their heirs,
                        successors and assigns;</p>

                    <p><strong>Conditions</strong> means these terms and conditions;</p>

                    <p><strong>Goods</strong> means any goods and/or services sourced by Us or provided by Us in connection with any such goods and/or services
                        including computer hardware and Software and any goods or services provided in connection with any of those things;</p>

                    <p><strong>GST</strong> has the meaning given to it under A New Tax System (Goods and Services Tax) Act 1999 (Cth);</p>

                    <p><strong>Order</strong> means any order, or approved quote, or approved proposal, requested by You to Us for Goods or Services in any form;</p>

                    <p><strong>Quote or Proposal</strong> means a quote provided to You by Us;</p>

                    <p><strong>Period or Term </strong>means a particular number of half-days, days, weeks, fortnights, months, or any other period, as may be
                        agreed between Us and the You as the period during which some Services will be provided;</p>

                    <p><strong>Plan or Agreement</strong> means any arrangement between Us and You (whether alone or in conjunction with any other person) for
                        Services and/or the provision of Goods provided by Us under an arrangement in connection with Work agreed to be done or progressed for
                        or on behalf of You or any other person at Your request, including as set out in a Plan Schedule;</p>

                    <p><strong>Plan Schedule</strong> means the key terms applicable to Plans as set, and as may be varied by Us, from time to time in
                        its absolute discretion without notice to You;</p>

                    <p><strong>Public Holidays</strong> means any day which a public holiday is throughout New South Wales other than a bank holiday;</p>

                    <p><strong>Rates</strong> means the hourly rates and other charges for Services (including any call-out fees and any Return/Cancellation Fees)
                        set out in the Agreement Summary, Investment Summary, Rates Schedule, a Plan, Plan Schedule, Quote, Proposal, contract or arrangement entered
                        into by Us and You or in these Conditions, and includes any monies payable to Us on a quantum merit basis for any work it has done;</p>

                    <p><strong>Rate Schedule</strong> means the schedule of rates, charges and conditions for the services of Ours as set, and as may
                        be varied, by Us from time to time in its absolute discretion without notice to You;</p>

                    <p><strong>Reasonable Assistance Limits</strong> has the meaning set out in clause 18;</p>

                    <p><strong>Return/Cancellation Fee</strong> means a fee charged pursuant to clause 13 as set by Us from time to time;</p>

                    <p><strong>Service request</strong> means a request for service such as additions (adds), moves, changes and technical assistance;</p>

                    <p><strong>Services</strong> means the provision of any services by Us including Work, advice and recommendations;</p>

                    <p><strong>Software </strong>includes software and any installation, update, associated software and any services provided in connection with any of these things;</p>

                    <p><strong>Us, Our or We </strong>means Bizcomm Pty Ltd ABN 54620611467 and its heirs, successors and assigns; and</p>

                    <p><strong>Work </strong>means anything We may do, provide, customise, produce or acquire, whether or not in connection with, or for the purposes
                        of, You or Your use or benefit, and includes testing, troubleshooting, installation and configuration of new equipment or software, consulting,
                        scoping, planning, documenting and quoting for complex items;</p>

                    <p><strong>Working days or Business days</strong> means Monday to Friday, excluding Australian Federal and State Public holidays;</p>

                    <p>In these Conditions, the Rate Schedule and every Quote, Proposal, Order, Plan, contract, or other arrangement in connection with the supply of
                        Goods or Services by Us, unless the contrary intention appears:</p>

                    <p>Words denoting the <strong>singular</strong> number only <strong>shall include the plural</strong> number and vice versa;</p>

                    <p>Reference to <strong>any gender shall include every other gender;</strong></p>

                    <p>Reference to any <strong>Act of Parliament, Statute or Regulation shall include any amendment</strong> currently in force at the relevant
                        time and any Act of Parliament, Statute or Regulation enacted or passed in substitution therefore;</p>

                    <p><strong>Headings</strong> and words put in <strong>bold</strong> are for convenience of reference only and <strong>do not affect the
                        interpretation or construction</strong> of these Conditions;</p>

                    <p>All references to dollars ($) are to Australian Dollar (AUD) unless otherwise denoted or specified;</p>

                    <p>A reference to time is to <strong>Sydney, Australia time</strong> unless otherwise denoted or specified;</p>

                    <p>A reference to an <strong>individual or person includes a corporation</strong>, partnership, joint venture, association, authority,
                        trust, state or government and vice versa;</p>

                    <p>A reference to a recital, clause, schedule, annexure or exhibit is to a recital, clause, schedule, annexure or exhibit of or to these Conditions;</p>

                    <p>A recital, schedule, annexure or description of the parties forms part of these Conditions;</p>

                    <p>A reference to any agreement or document is to that agreement or document (and, where applicable,
                        any of its provisions), as amended, novated, supplemented or replaced from time to time;</p>

                    <p>Where an expression is defined, <strong>another part of speech or grammatical form of that expression has a corresponding meaning;</strong></p>

                    <p>A reference to <strong>includes</strong> means <strong>includes without limitation;</strong></p>

                    <p>A reference to <strong>will</strong> imports a condition not a warranty; and</p>

                    <p>A reference to <strong>bankruptcy or winding</strong> up includes bankruptcy, winding up, liquidation, dissolution, becoming an
                        insolvent under administration, being subject to administration and the occurrence of anything analogous or having a substantially similar
                        effect to any of those conditions or matters under the law of any applicable jurisdiction and to the procedures, circumstances and events
                        which constitute any of those conditions or matters.</p>

                    <p>Unless otherwise agreed by Us in writing, these General Terms and Conditions (“Terms and Conditions”) are deemed incorporated
                        in and are applicable to all IT services provided by Bizcomm Pty Ltd ABN 54620611467 (We, Our, Us) (and, subject always to the
                        Master Services Agreement, which shall be paramount, to the extent of any inconsistency will prevail over the terms of every Quote,
                        Proposal, Order, Plan, Agreement, contract, or other arrangement in connection with the supply of Goods and/or Services by Us to You.</p>

                    <p>The invalidity or enforceability of any one or more of the provisions of these Terms and Conditions will not invalidate, or render
                        unenforceable, the remaining provisions of these Terms and Conditions.</p>

                    <ol>
                        <li>All contracts of sale made by Us shall be deemed to incorporate these Terms and Conditions, which shall prevail over any
                            other terms of the party (Customer) with whom We are dealing.</li>
                        <li>Nothing in these Terms and Conditions is intended to impinge upon a Consumer’s statutory or contractual rights to reject faulty goods.</li>
                        <li><strong>Definitions</strong> Capitalised terms used in these Terms and Conditions have the following meaning unless otherwise defined:</li>
                        <ol type="a">
                            <li><strong>Agreement Summary</strong> means the agreement summary as defined in any Proposal;</li>
                            <li>Business Hours means our standard business hours unless those days and times are defined in the Agreement Summary;</li>
                            <li><strong>Client Particulars </strong>means Your details as listed in any Proposal;</li>
                            <li><strong>Disaster Recovery Event</strong> means as per Bizcomm Pty Ltd accepted proposal (if included in the proposal).</li>
                            <li><strong>Goods </strong>means any goods sourced by Us or provided by Us including computer hardware and Software and any goods or
                                services provided in connection with any of those things;</li>
                            <li><strong>EULA/End User Licence Agreement</strong> means any end user licence agreement;</li>
                            <li><strong>Insolvency Event</strong> means, in relation to a party, any one or more of the following events or circumstances:</li>
                            <ol type="i">
                                <li>being insolvent, under administration, in liquidation or having a receiver appointed;</li>
                                <li>being in liquidation or provisional liquidation or under administration;</li>
                                <li>having a controller or analogous person appointed to it or any of its property;</li>
                                <li>being taken to have failed to comply with a statutory demand;</li>
                                <li>being unable to pay its debts or being otherwise insolvent;</li>
                                <li>becoming an insolvent under administration;</li>
                                <li>entering a compromise or arrangement with, or assignment for the benefit of, any of its members or creditors;</li>
                                <li>any analogous event or circumstance under the laws of any jurisdiction; or</li>
                                <li>taking any step or being the subject of any action that is reasonably likely to result in any of the above occurring,</li>
                                <li>unless such event or circumstance occurs as part of a solvent reconstruction, amalgamation, compromise, arrangement, merger
                                    or consolidation approved by the Supplier (which approval is not to be unreasonably withheld).</li>
                            </ol>

                            <li><strnog>Minimum Standards</strnog> means as per Bizcomm Pty Ltd accepted proposal;</li>
                            <li><strong>Order</strong> means a request for Goods or Services from You to Us in a form acceptable to Us;</li>
                            <li><strong>Period</strong> means several half-days, days, weeks, fortnights, months, or any other period, as may be agreed between
                                Us and the You as the period during which some Services will be provided;</li>
                            <li><strong>Personal Information</strong> has the meaning set out in the Privacy Act 1988 (Cth);</li>
                            <li><strong>Plan</strong> means any arrangement between Us and You (whether alone or in conjunction with any other person) for Goods and
                                Services provided by Us under an arrangement in connection with Work agreed to be done or progressed for or on behalf of You or any other
                                person at Your request, including as set out in a Plan Schedule;</li>
                            <li><strong>Professional Services</strong> means any Services so described in a statement of work;</li>
                            <li><strong>Proposal</strong> means the Bizcomm Pty Ltd Managed Services Proposal;</li>
                            <li><strong>Proposal Expiry</strong> means the date of expiry of the Proposal;</li>
                            <li><strong>Quote</strong> means any quote provided by Us to You;</li>
                            <li><strong>Rates</strong> means the hourly rates and other charges for Services (including any call-out fees and any Return/Cancellation Fees)
                                set out in the Agreement Summary, Investment Summary, Rates Schedule, a Plan, Plan Schedule, Quote, Proposal, contract or arrangement entered
                                into by Us and You or in these Terms and Conditions, and includes any monies payable to Us on a quantum merit basis for any work it has done;</li>
                            <li><strong>Rate Schedule</strong> means the schedule of rates, charges and conditions for the services of Ours as set, and as may be
                                varied, by Us from time to time in its absolute discretion without notice to You;</li>
                            <li><strong>Services</strong> means any services provided by Us including Work, advice and recommendations;</li>
                            <li><strong>Service Provider</strong> means Telstra, or any other provider supplier uses;</li>
                            <li><strong>Software</strong> means software and any installation, update, associated software and any services provided in connection with
                                any of these things;</li>
                            <li><strong>Third Party </strong>means any legal person or entity that is not party to these Terms and Conditions;</li>
                            <li><strong>Work</strong> means anything We may do, provide, customise, produce or acquire, whether or not in connection with, or for the
                                purposes of, You or Your use or benefit, and includes testing, troubleshooting, installation and configuration of new equipment or
                                software, consulting, scoping, planning, documenting and quoting for complex items.</li>

                        </ol>

                        <li><strong>Quotes/Proposals</strong></li>

                        <ol type="a">
                            <li><strong>Term and effect</strong> Quotes will only be valid for 30 days unless otherwise specified in the Quote, or otherwise advised
                                in writing. A Quote is merely an invitation to You to place an Order with Us and the acceptance of a Quote by You will not create a binding
                                contract between You and Us.</li>

                            <li>The Quote is valid for period specified on the Quote/proposal. Expiry dates on Quotes are set to be able to inform Us when the quote is still active
                                or to be discarded. Once discarded the Quote will need to be requested again.</li>

                            <li>Once a Quote has been confirmed by Us, then the prices in the Quote will be confirmed as the final agreed price. A Quote is confirmed as ‘final’
                                as soon as both parties agree with the final price after any last changes requested by You.</li>

                            <li>The price in the final Quote may vary from the original request if there is any price or product changes requested by You. During the validity period
                                of the Quotation, vendor pricing may be subject to change based on product pricing adjustments or exchange rate fluctuations. These fluctuations lie
                                outside of our control; therefore, we reserve the right to update the product pricing accordingly.</li>

                            <li>Considerable care has been taken in the preparation of this quotation; however, inaccuracies may occur, and we cannot therefore accept responsibility
                                for any errors, omissions or changes during the validity of this quote. Should any errors or omissions be identified, we will update the Quote
                                accordingly and provide You with the revised Quote.</li>

                            <li>Quotes and estimates shall be deemed to correctly interpret the original specifications and are based on the cost at the time the Quote or estimate
                                is given. If You later require any changes to the Quotes, and We agree to the changes, these changes will be charged at Our prevailing rate.</li>

                            <li>Once the Quote has been confirmed and converted to an Order, the Order will be subjected to our normal Terms and Condition of Sale.</li>

                            <li>The general minimum turnaround time for Quote request to be actioned is usually 24 hours. If a Quote is required urgently, please let us know so
                                that we can respond to it accordingly.</li>

                            <li>When a special price or discount offer has been applied to this Quote, no other special promotion, discount or bonus offer will be applicable.</li>

                            <li>If products in the Quote are subjected to any price and supply fluctuations that is outside of Our control, we reserve the right to update the
                                price and product in the Quote accordingly before or after Quote approval. If a product has undergone a price drop or a price increase, the Quote
                                will then be adjusted accordingly. If there is a product that is no longer available, the product will then be replaced or substituted based on
                                Your request and is subject to Your final approval.</li>

                            <li>Price on non-stocked products are subjected to Price and stock fluctuations and can only be confirmed once the Quote is turned into an Order.
                                While We endeavour to honour every price quoted, if there is a price increase that is beyond our control, we reserve the right to increase
                                the price as necessary.</li>

                            <li>Once a Quote has already passed the expired date, we may cancel the Quote or estimate without having to notify or receive an approval from You.</li>

                            <li>ETA information is based on an estimate given by our vendors and cannot be held as the actual promised date.</li>

                            <li>Freight on the quoted product is subject to price fluctuations and can only be confirmed once the Quote has been converted to an Order. While
                                we endeavour to honour every price quoted, if there is a price increase that is beyond our control, we reserve the right to increase the price
                                as we deem necessary.</li>

                            <li>We do not keep inventory, and as such only order items once we receive a completed Order from You. If You would like to return an item or cancel
                                an Order, a restocking fee may apply. We will need to get approval from the distributor that the stock is returnable before being able to issue
                                a refund as not all products can be returned.</li>

                            <li>Prices are quoted on the basis that all Quoted items are purchased.</li>

                            <li>Unless specified, all items on the Quote are covered by manufacturer’s warranty covering parts and labour for hardware only on a return to depot basis.</li>

                            <li><strong>Varying or withdrawing Quotes</strong> We may vary or withdraw a Quote at any time in Our absolute discretion and without prior notice
                                to You. We may do so for any reason We consider fit, including, where the Goods or Services become unavailable or the cost price of Goods or
                                Services increases after the date of the Quote.</li>

                            <li>We will have no liability to You or an end user in relation to any loss, damage or expense caused, Our failure to complete an order or
                                delivery or contract as a result of fire, flood, tempest, earthquake, riot, civil disturbance, theft, crime, strike, lock-out, war or the
                                inability of Our suppliers to supply necessary materials, or any other matter beyond Our control.</li>
                        </ol>

                        <li><strong>Orders</strong></li>

                        <ol type="a">
                            <li><strong>Order Forms</strong> You may place an Order for Goods and/or Services with Us. Normally, We will require that You provide either a completed
                                Order Form, Official Purchase Order, or You approve the Quote electronically via either an email or a web based system with the date and Your details,
                                including Your full legal name or description and any applicable ABN or ACN number (including the full name or description of any person on whose
                                behalf the order is placed), Your address together with any relevant Quote number and date.</li>

                            <li><strong>Approval of Orders</strong> You will need to sign the Order or have it duly executed on Your behalf, unless the Order is sent by email
                                or via the web based ordering system, in which case the Order will be treated or deemed as if signed by or on behalf of You by the person whose
                                name appears as the sender of the email or submitter of the Order Form.</li>

                            <li><strong>Reliance on appearance of validity</strong> In the absence of actual knowledge to the contrary, we may rely upon the apparent validity
                                of an Order. If any Order is signed or sent by email or approved through the web-based ordering system by a named person, that person warrants
                                that the Order is, and it is acknowledged the Order is deemed in favour of Us to be:</li>

                            <ol type="i">
                                <li>Signed by, and duly authorised by, both the person who signed the Order and the person who sent the email; and</li>

                                <li>Duly authorised by the person on whose behalf the Order is placed or apparently placed.</li>
                            </ol>

                            <li><strong>Acceptance and Orders</strong> An Order has no effect unless or until it is accepted by You in writing and, until We have received from You payment in clear funds for the Order and any related freight, delivery and (where applicable) in-transit insurance costs in clear funds.</li>

                            <li><strong>No obligation to deliver</strong> We are not obliged to deliver any Order until we have received payment in clear funds from You for the Order, any related freight, delivery and (where applicable) in-transit insurance costs or where We are unwilling or unable to complete the Order for any reason provided it refunds any payment made by You in respect of the Order.</li>

                            <li><strong>Credit checks</strong> For the purposes of ascertaining the credit standing or history of a prospective customer to whom We are considering extending credit or payment terms, you hereby consent to Us undertaking a credit reference check in respect to You.</li>

                            <li><strong>Cancellation of Orders</strong> You will not cancel an Order unless We agree to do so in writing in Our absolute discretion. You acknowledge that, amongst other things, we cannot cancel an Order once the manufacturer or supplier has dispatched the relevant Goods and that such dispatch often occurs the same day as the Order is placed by Us. A stocking fee may be applicable if you wish to cancel orders. Stocking fee will be dependent on our supplier’s rates and charges.</li>

                            <li><strong>Processes and Procedures</strong> We have processes and procedures that We follow during the provision of Our Services and the supply of Goods. You agree to co-operate with Us and to comply with such processes and procedures as advised to You from time to time.</li>

                        </ol>
                        <li><strong>Pricing and rates</strong></li>
                        <ol type="a">
                            <li><strong>Rates exclude Tax</strong> All rates and amounts charged or quoted for Goods and/or Services by Us are exclusive of Tax and any other applicable taxes or government charges (unless otherwise stated in writing by Us).</li>
                            <li><strong>Rates Schedule</strong> You must pay for Goods and Services at the Rates set out in any applicable Plan and the Rate Schedule as applicable from time to time during the provision of the Goods and/or Services.</li>
                            <li><strong>Vary Rates</strong> We reserve the right to vary any Rate and/or the Rate Schedule from time to time (subject to any fixed pricing for specific periods in any Plan), in its absolute discretion and without notice to You.</li>
                            <li><strong>Call-out fees</strong> You acknowledge that call-out fees may be charged in addition to the Rates at Our absolute discretion and that the amount of the call-out fee will depend upon where the Services are provided.</li>
                            <li><strong>Return/Cancellation Fee</strong> Where We arrange a return or refund on Your behalf, or where an Order is cancelled by You after acceptance by Us, we may charge You a Return/Cancellation fee to cover the administration costs to Us in processing the return or refund, or in processing the Order, the cancellation and any refund. We may deduct the Return/Cancellation fee from any moneys otherwise due to be refunded to You by Us.</li>
                            <li><strong>Expenses</strong> You must pay any out of pocket expenses incurred by Us in providing the Services to You in addition to the Rates, charges and call-out fees, upon written demand. Such expenses will include travel costs, flights, car hire, petrol, insurance, taxi fares, accommodation and related meal allowance, tolls and car parking expenses. Where appropriate, we will obtain prior written authorisation from You before such expenses are incurred.</li>
                            <li><strong>Separate charges for Goods and Services</strong> We may in Our absolute discretion charge for Goods separately from Services or may charge for Goods and Services together.</li>
                            <li><strong>Calculation of increments</strong> Where a charge is calculated based on increments of time, e.g. 1 hour or 30 minutes, we will charge the applicable rate for the whole increment of time even if work is done during part of, but not for the whole of, that increment of time.</li>
                            <li><strong>Change in underlying costs</strong> Without prejudice to any other rights of Ours under these Conditions, where there is any increase in the underlying costs incurred by Us in connection with the supply of Goods or Services to You, we may, in our absolute discretion, vary any of Our Rates upon written notice to You.</li>
                            <li><strong>Pre-Paid Blocks of Service</strong> Where You agree to buy pre-paid blocks of service during a Period, payment must be made in advance for the pre-paid blocks of service at the rate applicable pursuant to the Rates Schedule for all Services. Each such rate being less any discount agreed in writing between Us and You in respect of the pre-paid blocks of service. Services included in a pre-paid block of service rate during the Period:</li>
                            <ol type="i">
                                <li>Are calculated in accordance with the applicable minimum time periods and increments set out in the Rates Schedule; and</li>
                                <li>Are only provided by Us during the applicable Period. Where Services are provided for a specified Period:</li>
                                <li>The Services remaining unused for that Period cannot be rolled over into any subsequent Period.</li>
                            </ol>
                            <li>We are not liable to refund, reimburse, pay damages or otherwise compensate or indemnify You with respect of those unused Services.</li>
                            <li><strong>Term</strong> Where a Plan sets out an applicable term, e.g. six months or one year, unless you give Us written notice of the cancellation of your
                                Plan at least 60 days before the end of any applicable term, We will extend the term of your Plan automatically for another term equal to the original term
                                (or such other term as may be agreed between Us and You stated in the Agreement Summary) provided that You continue to Pay the current maintenance fee to the
                                Provider.</li>
                            <li>The amount payable in this quote is equal to the value of the products and services set out in a Quote plus any GST payable in accordance with the
                                guidelines set by the Australian Taxation Office.</li>
                            <li>Each party agrees to provide the necessary invoices and other documentation needed to enable or assist the other party to claim any credit, set-off,
                                rebate or refund in relation to any amount of GST paid or payable to any supply under this contract.</li>
                        </ol>

                        <li><strong>Services and plans</strong></li>
                        <ol type="a">
                            <li>Service and Plan Variations: Currently, we offer the Services and Plans referred to in the Rates Schedule and any Plan Schedule. We may withdraw the provision of, or vary the scope or terms of, or add to or change, the Services without notice to You, from time to time in Our absolute discretion.</li>
                            <li>Copies on Request: We will provide You with a copy of the current Rates Schedule upon request. Plan Schedules are tailored for particular Plans and are available to clients participating in the Plan.</li>
                            <li>Included Services: Services rendered under these Terms and Conditions include the following features:</li>
                            <ol type="i">
                                <li>All items listed as part of your selected Service Plan in the Agreement Summary.</li>
                                <li>Any items listed in the Additional Inclusions section of the Agreement Summary.</li>
                            </ol>
                            <li>Excluded Services: Services rendered under these Terms and Conditions do not include:</li>
                            <ol type="i">
                                <li>Parts, equipment, software or users not covered under the Service Plan or as specified in the Additional Inclusions of the Agreement Summary.</li>
                                <li>Parts, equipment or software not covered by vendor/manufacturer warranty or support.</li>
                                <li>The Professional Services labour costs associated with the setup of new computers and other hardware/software. These setup costs will be quoted as part of the support of the computer/hardware/software, etc.</li>
                                <li>The cost of any parts, equipment, or shipping charges of any kind.</li>
                                <li>The cost of any Software, licensing, or software renewal or Upgrade Fees of any kind.</li>
                                <li>The cost of any 3rd Party Vendor or manufacturer support or incident gees of any kind.</li>
                                <li>The cost to bring Your environment up to Minimum Standards required for service compliance.</li>
                                <li>Failure due to acts of God, building modifications, power failures or other adverse environmental conditions or factors.</li>
                                <li>Service and repair made necessary by the alteration or modification of equipment other than that authorised by Service Provider. This includes alterations, software installations or modifications of equipment made by Your employees or anyone other than Service Provider.</li>
                                <li>Maintenance of application software packages, whether acquired from Service Provider or any other source unless as specified in the Additional Inclusions of the Agreement Summary.</li>
                                <li>Programming (modification of software code) and program (software) maintenance unless specified in the Additional Inclusions of the Agreement Summary.</li>
                                <li>Training services of any kind not specifically included.</li>
                                <li>Services performed outside of the Service Plan hours as outlined in the Agreement Summary.</li>
                                <li>Any work, activities, or costs associated with restoration of services in a Disaster Recovery event. This work will be billable on an &lsquo;as-used&rsquo; basis.</li>
                                <li>Microsoft Office 365 Migration Services, and any other data migration services.</li>
                                <li>Travel Costs: Travel expenses outside of those included in these Terms and Conditions. Airfare, Hotel and Car Rental will be billed at cost, and Per Diem Expenses will conform to Australian Taxation Office (ATO) standards.</li>
                            </ol>
                        </ol>
                        <li><strong>Delivery, title and risk</strong></li>
                        <ol type="a">
                            <li>Delivery liability: We will use all reasonable endeavours to dispatch Goods by the due date, but do not accept any liability for non-delivery or failure to deliver on time where this is caused by circumstances beyond Our reasonable control, including failures in supply to Us or delays caused by third parties, such as delivery companies, manufacturers, unexpected illness, or absence, etc.</li>
                            <li>Availability to accept delivery: You must be available to accept the Goods at Your nominated delivery address during Business Hours unless otherwise arranged.</li>
                            <li>Passing of Risk: Risk in all Goods shall pass to customer immediately upon:</li>
                            <ol type="i">
                                <li>Departure of Goods from manufacturer/ Supplier/Our warehouse for delivery to You or an end user (where the Goods are delivered to the end user&rsquo;s address), Your agent or into Your custody or control; or</li>
                                <li>collection by You, or Your nominated carrier or agent.</li>
                                <li>Nothing in this clause will affect title to the Goods.</li>
                            </ol>
                            <li>You are not relieved of Your obligation to accept or pay for the Goods by reason of a delay in delivery.</li>
                            <li>We shall use reasonable endeavours to keep You informed of any delay or potential delay, where such delay has been notified to Us.</li>
                            <li>Delivery and risk of Goods shall be Ex Works Bizcomm Pty Ltd&rsquo;s. You are responsible for insurance, transportation costs and all other ancillary costs associated with the delivery of the Goods to Your nominated delivery site. You are responsible for organising insurance coverage, transportation costs and all other ancillary costs associated with the delivery of the Goods from Your point of delivery to any other location.</li>
                            <li>Retention of Title: Until We receive full payment in cleared funds for any moneys due to Us by You on any account or for any reason:</li>
                            <ol type="i">
                                <li>Title to, and property in, Goods supplied to You remain vested in Us and does not pass to You;</li>
                                <li>You must hold those Goods as fiduciary bailee and agent for Us and must not sell them;</li>
                                <li>You must keep those Goods separate from other goods and maintain the Goods and their labelling and packaging intact;</li>
                                <li>Where You sell the goods in breach of these Terms and Conditions, You are required to hold the proceeds of any sale of those Goods on trust for Us in a separate account (however any failure to do so will not affect Your obligation to deal with the proceeds as trustee and remit them to Us);</li>
                                <li>We may, without prior notice, enter into any premises where We suspect those Goods may be, take possession of those Goods and sever and remove those Goods (notwithstanding that they may have been attached to other goods not the property of Ours) and for this purpose, You hereby irrevocably authorise and direct Us (and Our employees and agents) to enter into such premises as its duly authorised agent and You hereby indemnify and hold harmless Us from and against any costs, claims, allegations, demands, damages or expenses or any other acts or omissions arising from or in connection with, such entry, repossession or removal, except to the extent that any harm is directly and solely caused by Our negligent action.</li>
                                <li>You irrevocably appoint Us as Your attorney to do anything We consider necessary in order to enter such premises and repossess the Goods as contemplated by this clause.</li>
                            </ol>
                        </ol>
                        <li><strong>Resale/use</strong></li>
                        <ol type="a">
                            <li>All Goods supplied by Us may only be for a customer within Australia, unless otherwise agreed in writing.</li>
                            <li>You agree not to re-sell or supply the Goods to any Third Party that You know, or should have known after making reasonable enquiries, is likely to sell or supply outside of Australia or further supply or on-sell the Goods.</li>
                            <li>You agree that you may, from time to time, be required by OEM, or by Us as advised by OEM, to comply with OEM&rsquo;s terms or instructions relating to Goods manufactured by the OEM.</li>
                            <li>You agree and understand that a breach under this clause shall be deemed a material breach, giving Us the right to terminate any Plan or Order immediately with You. You will defend, indemnify and hold Us harmless, Our related bodies corporate, affiliates and each of Our/Our related bodies corporate/Our affiliates&rsquo; respective officers, directors, employees, shareholders, representatives and agents from and against all claims, demands, proceedings, actions, liabilities, losses, damages, costs or expense of any kind (including reasonable legal fees) incurred or sustained as a result of, or relating to Customer&rsquo;s failure to comply with this clause 8.</li>
                        </ol>
                        <li><strong>Returns, changes and claims for goods and services</strong></li>
                        <ol type="a">
                            <li>General Returns Policy: Notwithstanding anything in these Conditions, You acknowledge that We supply Goods subject to all applicable conditions, including returns and claims policies, of any relevant manufacturer or supplier. You will accept Goods subject always to these Terms and Conditions and will indemnify and hold Us harmless in respect of any further or other obligation or any failure or default on the part of that manufacturer or supplier.</li>
                            <li>Customised Goods not returnable: Where Goods have some element of customisation for you, are supplied pursuant to an Order for Goods that is in the opinion of Ours special or unusual, the Goods are obtained from overseas, the Goods are obtained from a supplier who is no longer trading, or the Goods are otherwise not readily returnable by Us to the manufacturer or supplier or any related services may not be cancelled, You may not return the Goods to Us or cancel the related Services.</li>
                            <li>If a return of goods is accepted, then it will be subject to a restocking charge of 15%.</li>
                            <li>You accept that We are not obligated to accept any modifications or cancellations to a Purchase Order once accepted by Us. Modifications and cancellations to a Purchase Order shall only be valid where We have provided You with written acceptance of such modification or cancellation.</li>
                            <li>Defective Goods will be returned to OEM as per their standard warranty and returned when repaired.</li>
                            <li>Modifications and cancellations may be subject to additional charges as specified by Us.</li>
                            <li>Duty to inspect: You will inspect all Goods immediately upon their delivery. Within 7 days of such delivery You may give written notice to Us of any matter or thing, by reason of which You might wish to return the Goods, ask for a refund, or make a claim. If no such notice is given on time, and subject always to the relevant law, you will accept the Goods without any such return, refund or claim.</li>
                            <li>Return Condition: Where You are entitled to return Goods under these Terms and Conditions, You must return the Goods in their original condition and unopened, provided always that where, upon opening the packaging it becomes apparent that the Goods are different to what is described on the packaging or that the Goods are faulty, the Goods may be returned.</li>
                            <li>Return costs: You will pay all costs and expenses incurred by Us in arranging the return of the Goods to a manufacturer or supplier and/or the cancellation of any related services unless that manufacturer or supplier pays such costs.</li>
                            <li>Consequences of use, installation, customisation or sale: You will indemnify and hold Us harmless in respect of all allegations and claims relating to the Goods once such Goods have been used, installed, customised or re-sold by You (without prejudice to the recourse of such a customer to the manufacturer of the Goods).</li>
                        </ol>
                        <li><strong>Warranties</strong></li>
                        <ol type="a">
                            <li>All Goods and Services are covered by OEM&rsquo;s warranty. To the full extent permitted by law and subject to clause 10(c), Our entire responsibility with respect to warranties for the Goods is to pass to You the benefit of any such OEM&rsquo;s warranty that is provided to Us for such Goods and/or Services.</li>
                            <li>You warrant to Us that it You are acquiring the Goods and/or Services for the purpose of resale within the course of Your business, and that it You are not purchasing the Goods and/or Services wholly or predominantly for personal, domestic or household use or consumption.</li>
                            <li>In the event that the Goods are subject to a Consumer Guarantee as defined in the Competition and Consumer Act 2010 (Cth) (&ldquo;Australian Consumer Law&rdquo;), nothing in these Terms and Conditions excludes, restricts or attempts to modify any condition, warranty or other obligation in relation to the Goods supplied hereunder. The following statement shall apply where the Goods are deemed to be a consumer sale to You by Us under the Australian Consumer Law.</li>
                        </ol>
                        <li><strong>Computer utility, functionality and fitness for purpose</strong></li>
                        <ol type="a">
                            <li>Service limitations given the science of computing: You acknowledge that a reasonable incident of the Services may involve trial and error and that it is a science applied often in novel or unknown circumstances and involving experiment. In particular, you acknowledge that the Services may involve tests, troubleshooting, advice and recommendations that may prove incorrect or inappropriate, particularly in an attempt to cure a problem You are having. While We will make what We consider (in Our absolute discretion) to be all reasonable endeavours to provide appropriate tests, troubleshooting, sound advice and good recommendations in order to assist You, you will always indemnify and hold Us harmless in the provision of our Services to You.</li>
                            <ol type="i">
                                <li>Cost of consumables, replacement parts, hardware, Software, network upgrades and associated services are outside the scope of these Terms and Conditions: We will provide consultative specification, sourcing guidance and/or time and material/project offerings.</li>
                                <li>Manufacturer warranty parts and labour/services are outside the scope of these Terms and Conditions.</li>
                                <li>Periodic reboots for such devices as firewalls, routers, and servers are required to apply/activate critical update patches and configuration changes. Our support services within these Terms and Conditions are predicated upon Your support and commitment to providing time/scheduling for network device reboots with its staff and/or users support unless urgently required to rectify a critical issue.</li>
                                <li>Application software support is limited to the manufacturer&rsquo;s product support and an active support agreement between You and the application vendor.</li>
                                <li>Printer hardware maintenance support is not included and should be managed between You and their printer management provider or printer vendor.</li>
                                <li>Virus mitigation within the scope of these Terms and Conditions is predicated on You satisfying recommended backup schemes and having appropriate endpoint and server protection software with current licensing and updates.</li>
                                <li>Restoration of lost data caused by systems/hardware failure is outside the scope of these Terms and Conditions.</li>
                                <li>These Terms and Conditions and support services herein are contingent on Your permission of Us having secure remote access into Your network (e.g. VPN, Remote Desktop Services, Telnet, SSH, RMM or other solution expressly approved by Us). Depending on the remote access solution used, additional charges may apply.</li>
                                <li>Support services required or requested outside the scope of these Terms and Conditions may not be exchanged for days or services within this agreement. Outside of scope support services are available and will be provided on either a Time and Material, or Project basis.</li>
                            </ol>
                        </ol>
                        <ol type="a">
                            <li>Reasonable Assistance Limits: We are only obliged to provide what We consider, in Our absolute discretion, to be reasonable assistance in the circumstances (including with the installation and customisation of new software or hardware for You or any other Work) under any Plan and You will pay for additional work at the Rates unless otherwise agreed. Without limiting the discretion of Us to determine what reasonable assistance is, normally, reasonable assistance is limited to work done during Business Hours over a period of time not exceeding any period that We have allowed or allows for the Work or has estimated or estimates the Work will take, whether or not notice of the time allowed or estimate is given by Us to You.</li>
                            <li>Recommendations, suitability, functionality and fitness for purpose: The parties acknowledge that:</li>

                            <ol type="i" start="10">
                                <li>We may recommend that You purchase Goods provided by third parties from time to time;</li>
                                <li>Recommendations may be made in situations where You have made known to Us the purpose for which the Goods will be used, or some function sought to be fulfilled;</li>
                                <li>You acknowledge that We have no control over many factors involved with the suitability, function or fitness for purpose of Goods in an existing or new computer environment, e.g.</li>
                            </ol>
                        </ol>
                        <ol>
                            <li>The compatibility or ability of the Goods to fit into or perform to expectations in the receiving computer/internet environment; or</li>
                            <li>The behaviour of third-party supplier, e.g. In relation to support;</li>
                            <ol type="i" start="13">
                                <li>
                                    <p>You acknowledge that for a whole number of reasons outside of Our control, the Goods may fail to meet Your expectations, may not turn out to be fit for all or any of the purposes sought, may not be suitable or may not function properly in all or any respects;</p>
                                </li>
                                <li>
                                    <p>You acknowledge that the Services provided by Us may involve the very task of seeking to customise Goods so they may be fit for particular purposes and that customisation may be a very substantial project in itself;</p>
                                </li>
                                <li>
                                    <p>Accordingly, you will accept the sole responsibility for, and indemnify and hold Us harmless in respect of:</p>
                                </li>
                            </ol>
                        </ol>
                        <ol>
                            <li>Decisions as to whether to follow recommendations by Us;</li>
                            <li>Decisions as to whether to purchase or customise Goods or obtain Services for that or any other purpose; and</li>
                            <li>Any failure or defect in suitability, function or fitness for purpose of any Goods and/or Services, including a responsibility to obtain Your own independent advice or second opinion from a suitably qualified person;</li>
                            <ol type="i" start="16">
                                <li>
                                    <p>xvi. Where We provide Services with a view to achieving Your purposes, suitability, function or fitness for purpose (whether expressed, agreed or otherwise), You must pay for those Services on time without any set-off or counter-claim, whether or not We are able to achieve any of such purposes, suitability, function or fitness for purpose, provided always that We have acted in good faith and have made what We consider, in Our absolute discretion, to have made all reasonable endeavours to achieve those outcomes.</p>
                                </li>
                                <li>
                                    <p>Minimum Service Compliance Standards: We require that Your IT environment meets a minimum standard to ensure that We can meet the obligations of these Terms and Conditions. As such, there may be remedial actions required to lift Your IT environment to the minimum standard. We will communicate the required changes (if any) with the customer upon completion of an IT audit and/or onboarding exercise. For Your existing environment to qualify for Service Provider services, the following requirements must be met:</p>
                                </li>
                            </ol>
                        </ol>
                        <ol>
                            <li>All Servers with Microsoft Windows operating systems must not be in an end of life state and have all the latest Microsoft service packs and critical updates installed.</li>
                            <li>All desktop PC&rsquo;s and notebooks/laptops with Microsoft Windows operating systems must not be in an end of life state and have all the latest Microsoft service packs and critical updates installed.</li>
                            <li>All Server and desktop software must be genuine, licensed and vendor supported.</li>
                            <li>The environment must have a currently licensed, up-to-date and vendor-supported antivirus solution protecting all servers, desktops, notebooks/laptops, and email.</li>
                            <li>The environment must have a currently licensed, Vendor-Supported backup solution.</li>
                            <li>The environment must have a currently licensed, Vendor-Supported hardware firewall between the Internal Network and the Internet.</li>
                            <li>Any wireless data traffic in the environment must be secured with a minimum of 128bit data encryption</li>
                            <ol type="i" start="18">
                                <li>Costs required to bring Your environment to Minimum Standards are not included in these Terms and Conditions and will be quoted and billed separately.</li>
                                <li>We will recommend changes and improvements to the customers IT environment over time. This will be based on, but not limited to, best practices, changes in the technology landscape, industry trends, and emerging threats/risks. Should You choose to decline, or not implement fully, such changes, we will not be liable for any negative impact to You which has been incurred by not implementing the recommendation(s) presented by Us.</li>
                                <li>Should any required recommendations, solutions, or critical fixes not be accepted by You, we may exercise Our right to refuse service until the required recommendation/change is accepted and implemented.</li>
                                <li>Up until any required recommendations, solutions, or critical fixes are accepted by You and implemented by Us, you release Us from any Service Level Agreement (SLA) obligations.</li>
                            </ol>
                        </ol>
                        <ol type="a" start="2">
                            <li>Testing Procedures: You will follow Our instructions with regard to testing or troubleshooting any problems and that if those do not resolve the outstanding problems, We will, subject to these Terms and Conditions, allocate such resources as We consider reasonable in the circumstances towards their resolution.</li>
                        </ol>
                        <li><strong> Liability and Exclusions</strong></li>
                        <ol type="a">
                            <li>You acknowledge by entry into these Terms and Conditions or acceptance of Services with/from Us, You agree to all Bizcomm Pty Ltd Pty Ltd General Terms and Conditions and that no promise, representation, warranty or undertaking whether implied or otherwise, has been made or given by Us or any person on Our behalf in relation to the provision of the Goods and/or Services, except as set out in these Terms and Conditions. You undertake and warrant that You have relied on Your own skill and judgement in its decision to acquire the Goods and/or Services.</li>
                            <li>Except as specifically set out in these Terms and Conditions and so far as may be permitted by law, any term, condition or warranty in respect of the quality, fitness for purpose, condition, description, assembly, manufacture, design or performance of the Goods or Services, whether implied by statute, common law, trade usage, custom or otherwise, is hereby expressly excluded.</li>
                            <li>No liability for program or data loss: You indemnify and hold Us harmless in respect of any allegation, claim, loss or expense of Yours or any third party for any program or data loss or damage suffered by You or that third party arising directly or indirectly from the supply of the Goods or Services by Us to You. You acknowledge that You are solely responsible for backing up Your programs and data in order to mitigate Your own potential loss of programs and data.</li>
                            <li>To the full extent permitted by law, where the benefit of any condition, warranty or other obligation pursuant to the Australian Consumer Law or similar legislation is so conferred upon You, Our sole responsibility for breach of any such condition, warranty or other obligation shall be limited (except as otherwise set forth herein) at Bizcomm Pty Ltd&rsquo;s discretion to:</li>
                            <ol type="i">
                                <li>the replacement or cost of replacing the Goods and/or Services;</li>
                                <li>repairing or the cost of repairing the Goods and/or Services or</li>
                                <li>the re-supply or the cost of having the Goods and/or Services re-supplied (collectively known as &ldquo;Available Remedies&rdquo;).</li>
                            </ol>
                            <li>We will not have any liability for:</li>
                            <ol type="i">
                                <li>failure to deliver Goods within a specified time period;</li>
                                <li>availability and/or delays in the delivery of the Goods;</li>
                                <li>discontinuation of Goods, products lines or part thereof;</li>
                                <li>cancellation of any Purchase Order by OEM; and</li>
                                <li>arising from or relating to any actual or alleged violation or infringement of any patent, trademark, copyright or other intellectual property belonging to the OEM by the Goods.</li>
                            </ol>
                            <li>To the extent permitted by law, all prior discussions, quotations, other warranties whether implied or otherwise not set out in these Terms and Conditions are excluded and We are not liable in contract, tort (including, without limitation, negligence or breach of statutory duty) or otherwise to compensate You or any Third Party for any loss of data, loss of profits, loss of anticipated savings, economical loss or interruption of business, loss of use, loss of opportunity, of for any indirect, direct, consequential, special loss, whether or not We have been advised of the possibility of such damage or expense.</li>
                            <li>You shall be liable to Us for all Purchase Orders placed with Us, regardless of whether those Purchase Orders were not placed in accordance with Your authorisations or instructions. It is Your responsibility to ensure that its account is only accessed and used by Your authorised personnel, pursuant to and in accordance with, any limits on their authority and safeguarded from misuse by authorised or unauthorised individuals.</li>
                            <li>You are liable for, and will defend, indemnify and hold Us harmless, Our related bodies corporate, affiliates, and each of Our respective officers, directors, employees and agents from and against any claims, demands, proceedings, actions, liabilities, losses (including indirect, direct, consequential and special loss), damages, cost or expenses of any kind (including reasonable legal fees) incurred or sustained as a result of, or arising out of, or relating to any unauthorised actions taken by You, in the manner in which You market the Goods and/or Services, or any breach of any applicable laws or regulations relating to Your storage, marketing or sale of the Goods and/or Services.</li>
                            <li>Our only liability with respect to any hardware damaged Goods, defective Goods, or Goods that are erroneously delivered with be the return rights on the precedent condition that the following having been met:</li>
                            <ol type="i">
                                <li>the Goods are returned to Us within seven (7) days of the invoice date;</li>
                                <li>the list price of the Goods has not changed;</li>
                                <li>the Goods are new and unused, have not been added to, varied or otherwise modified;</li>
                                <li>the Goods are in their original package and not damaged (where the Customer alleges that the Goods have been damaged in transit, Customer will need to provide evidence of the Proof of Delivery (POD)); and</li>
                                <li>We determine in Our sole judgement that You are of good standing.</li>
                                <li>Goods returned under clause 13(c)will be subject to a restocking charge of 15%.</li>
                                <li>Defective Goods will be returned to OEM as per their standard warranty and returned when repaired.</li>
                            </ol>
                            <li>Except as otherwise expressly stated in these Terms and Conditions, we are not liable for any loss or damage of any kind however caused which is suffered or incurred by You in connection with:</li>
                            <ol type="i">
                                <li>Services provided to You or any Work under these Terms and Conditions;</li>
                                <li>Your use of Our website (including the use of a credit card or other debit device) or any linked website;</li>
                                <li>The non-availability of Goods or Our Services for any reason;</li>
                                <li>Any act or omission of Ours or the provision of inaccurate, incomplete or incorrect information by You.</li>
                            </ol>
                            <li>If notwithstanding the above, We are found liable to You or any Third Party, Our total liability for any claim or series of claims arising out of the same occurrence, or series of occurrences in connection with these Terms and Conditions or any agreement (including a Purchase Order) shall not exceed the value of the amount equal to the price paid by You to Us during the last six (6) month period preceding the event or circumstances giving rise to the liability.</li>

                        </ol>
                        <li>The prices shown above are valid for until the noted Proposal Expiry date under the Client Particulars section.</li>
                        <li>The prices above are based on the information provided by You and discoverable by Us at the time. Should any of this information change then the pricing may also increase or decrease based on the new information.</li>
                        <li>We reserve the right to adjust prices quoted if the scope of work is changed or if the defined assumptions are found to be incorrect. The prices and quantities above are based on the information provided or obtained at the time of this proposal. Actual invoiced charges will vary from month to month depending on actual consumption and quantities at the time of invoicing. We reserve the right to review and adjust unit pricing based on volume changes, or any other major change which may impact Our operating costs (such as client location changes, etc).</li>
                        <li><strong>Personal Property Securities Act 2009 (Cth) (&ldquo;PPSA&rdquo;)&nbsp;</strong>Defined terms in this clause have the same meaning as given to them in the PPSA.</li>
                        <ol type="a">
                            <li>If We already have a security interest in the Goods that We supply to You, then that security interest shall continue under these Terms and Conditions.</li>
                            <li>Where clause 16(a)is not applicable, both parties acknowledge that these Terms and Conditions constitute a Security Agreement and entitles Us to claim a Purchase Money Security Interest (&ldquo;PMSI&rdquo;) in Our favour over the Collateral supplied or to be supplied to You as Grantor pursuant to these Terms and Conditions.</li>
                            <li>The Goods supplied or to be supplied under these Terms and Conditions fall within the PPSA classification of &ldquo;Other Goods&rdquo; acquired by You and/or End User pursuant to these Terms and Conditions.</li>
                            <li>The parties acknowledge that We, as a Secured Party, are entitled to register Our interest in the Goods supplied or to be supplied to You, as Grantor, under these Terms and Conditions on the PPSA Register as Collateral.</li>
                            <li>To the extent permissible at law, You:</li>
                            <ol type="i">
                                <li>waive Your right to receive notification of, or a copy of any Verification Statement confirming registration of a Financing Statement or a Financing Change Statement relating to a Security Interest granted by You, as Grantor, to Us;</li>
                                <li>agree to indemnify Us on demand for all costs and expenses, including legal costs and expenses on a solicitor / client basis, associated with the;</li>
                            </ol>
                            <ol>
                                <li>registration or amendment or discharge of any Financing Statement registered by or on Our behalf;</li>
                                <li>enforcement or attempted enforcement of any Security Interest granted to Us by the You.</li>

                            </ol>
                            <ol type="i" start="3">
                                <li>agree that nothing in sections 130 and 143 of the PPSA will apply to these Terms and Conditions or the Security under these Terms and Conditions.</li>
                                <li>agree to waive its right to do any of the following under the PPSA:</li>
                                <li>receive notice of removal of an Accession under section 95;</li>
                                <li>receive notice of an intention to seize Collateral under section 123;</li>
                                <li>object to the purchase of the Collateral by the Secured Party under section 129;</li>
                                <li>receive notice of disposal of Collateral under section 130;</li>
                                <li>receive a Statement of Account if there is no disposal under section 132(4);</li>
                                <li>receive a Statement of Account under section 132(3)(d) following a disposal showing the amounts paid to other Secured Parties and whether Security Interests held by other Secured Parties have been discharged;</li>
                                <li>receive notice of retention of Collateral under section 135;</li>
                                <li>redeem the Collateral under section 142; and</li>
                                <li>reinstate the Security Agreement under section 143. 22.7.</li>
                            </ol>
                            <li> All payments received from the Customer must be applied in accordance with section 14(6)(c) of the PPSA.</li>
                        </ol>
                        <li><strong>Software</strong></li>
                        <ol type="a">
                            <li>All Software licences are Your responsibility and not Our responsibility. It is Your duty to store all licences for all Software used, so that that they can be reproduced when required. This includes all Software installed by Us.</li>
                            <li>You indemnify and hold Us harmless against any claim, allegation, loss, damage or expense arising directly or indirectly from:</li>
                            <ol type="i">
                                <li>any unauthorised Software use by You;</li>
                                <li>any breach of any Software licence in respect of Software provided to Us by You to be installed on one of Your computers;</li>
                                <li>otherwise as a result of Us installing Software on Your computers where You are not authorised to use the Software; and</li>
                                <li>any problem, defect or malfunction associated with any Software (or related services) supplied by third parties.</li>
                            </ol>
                            <li>All copyright in custom software remains the sole property of Ours unless alternate arrangements are made as part of a separate software agreement.</li>
                        </ol>
                        <li><strong>Proprietary Rights</strong></li>
                        <ol type="a">
                            <li>a. You acknowledge and agree that the provision by or through Us of any evaluation copies of any Goods, or of any Goods under licence (whether by Us or by a Third Party licensor) will not give You any right, title or interest in any such Goods or related documentation other than as provided for in the relevant End User License Agreement (”EULA”). Except as provided for in the relevant EULA, all other aspects of such Goods and related documentation including without limitation, programs, methods of processing, design and structure of individual programs and their interaction and programming techniques employed therein shall remain the sole and exclusive property of the relevant licensor and shall not be sold, revealed, disclosed or otherwise communicated, directly or indirectly, by You to any person, company or institution whatsoever. In addition, you agree:</li>
                            <ol type="i">
                                <li>not to modify or create any derivative work of the evaluation copies of any Goods or of any Goods under licence or any portion thereof;</li>
                                <li>not to delete, alter, add to or fail to reproduce in or on any copy of the evaluation copies of any Goods or of any Goods under licence and any copyright or other notices appearing in or on any copy, media or master or package materials;</li>
                                <li>not to reverse assemble, decompile, reverse engineer or otherwise attempt to derive source code (or the underlying ideas, algorithms, structure or organization) from any evaluation copies of any Goods or of any Goods under licence</li>
                                <li>not to copy any evaluation copies of any Goods or of any Goods under licence or include any portion of any evaluation copies of any Goods or of any Goods under licence in any other software program;</li>
                                <li>not to provide use of any evaluation copies of any Goods or of any Goods under licence in a computer service business, rental or commercial timesharing arrangement;</li>
                                <li>not to develop any other products containing any of the concepts and ideas contained in any evaluation copies of any Goods or of any Goods under licence that are not readily apparent from normal use of any evaluation copies of any Goods or of any Goods under licence pursuant to the license(s) granted hereunder; and</li>
                                <li>not to develop methods to enable unauthorized parties to use any evaluation copies of any Goods or of any Goods under licence.</li>
                            </ol>
                            <li>All trademarks, brand names and the name of Goods remain the property of their respective owners and/or licensors.</li>
                            <li>You shall not:</li>
                            <ol type="i">
                                <li>remove, obscure or modify any label or other indication of copyright or other intellectual property rights on the Goods;</li>
                                <li>duplicate or reproduce any Goods or written material supplied by OEM without the explicit written consent of OEM; nor</li>
                                <li>re-sell the Goods other than in its original, unmodified and unused condition as set by OEM.</li>
                            </ol>
                            <li>You shall not reproduce or redistribute the Goods or, to the extent permitted by law, limit the end user’s liability for violations of an OEM’s intellectual property rights under the EULA.</li>
                        </ol>
                        <li><strong>License</strong></li>
                        <ol type="a">
                            <li>Where the term &ldquo;supply&rdquo; or &ldquo;delivery&rdquo; is used in these Terms and Conditions to refer to Goods that are Software (including where Software is supplied with the Goods), such terms shall mean the sale and purchase of a licensed copy of that software Good or a right to access a hosted copy of that software Good (regardless of whether such software is supplied in hard copy, electronically, or where access is otherwise provided to a copy of the software).</li>
                            <li>Use of the Goods is governed by the EULA provided to You with the purchase of the Goods prior to, or upon delivery of the Goods or through on-screen acceptance. You must communicate to End Users the license types applicable to the Goods ordered by the end user and You are solely responsible for ensuring that the end user has obtained or will obtain all applicable EULAs to allow end user to use the Goods. You shall obtain the end users written agreement to the relevant to the relevant license terms.</li>
                        </ol>
                        <li><strong>Copyright and confidentiality</strong></li>
                        <ol type="a">
                            <li>Warranty and breach: You warrant that any confidential or copyright information or intellectual property (of any kind and in any form held) or provided by You to Us belongs to You. In the event of any breach of this warranty, You will pay all sums due to Us as if such warranty had not been breached (and regardless of any non-performance of any obligation by Us on account of or in connection with the breach of such warranty). You indemnify and hold Us harmless in respect of any allegations, claims, loss, costs or expenses in connection with such breach of warranty by You.</li>
                            <li>Retention of title: All copyright and other intellectual property rights in any Work created, commissioned or acquired by Us in the course of the supply of Services by Us to You will be the exclusive property of Ours unless otherwise agreed in writing by Us and You.</li>
                            <li>Confidential Information: We acknowledge that while providing Services to You, we may learn from You certain non-public personal and otherwise confidential information relating to You, including Your customers, consumers or employees. We shall regard all information We receive which in any way relates or pertains to You, including Your customers, consumers or employees as confidential.</li>
                            <li>You also acknowledge that all information and services, consulting techniques, proposals, and documents disclosed by Us or which comes to Our attention during the course of business and provided under these Terms and Conditions constitute valuable assets of, and confidential and/or proprietary information to Us.</li>
                            <li>As such, both parties shall take all commercially reasonable steps to not disclose, reveal, copy, sell, transfer, assign, or distribute any part or parts of such information in any form, to any person or entity, or permit any of its employees, agents, or representatives to do so for any purpose except unless permitted in writing by the disclosing party or as required by applicable law.</li>
                        </ol>
                        <li><strong>Export Restrictions</strong></li>
                        <ol type="a">
                            <li>You will obtain all licenses, permits and approvals required by any government and will comply with all applicable laws, rules, policies and procedures of the Australian Government to allow for the purchase and re-sale of the Goods. Customer will indemnify and hold harmless Bizcomm Pty Ltd (including its bodies corporate, affiliates and each of their respective directors, officers, employees, agents, shareholders or representatives) for any violation or alleged violation by Customer of such laws, rules, policies and procedures.</li>
                            <li>Where OEM is domiciled in the United States or France, Customer understands and acknowledges that all obligations by Us to deliver and all Your obligations to accept and sell Goods and sublicense software Goods at locations outside the United States or France are subject to applicable export control laws. You agree to cooperate in providing the information and assistance requested by OEM in connection with export licences and approvals. You shall comply fully with all applicable laws relating to export and re-export of the Goods and You agree not to sell any Goods covered by these Terms and Conditions to anyone else, if the sale would constitute a violation of any laws or regulations of the United States or France.</li>
                        </ol>
                        <li><strong>Default Payment</strong></li>
                        <ol type="a">
                            <li>Your payment of an amount less than the purchase price stated on the invoice will not be deemed as acceptance of payment.</li>
                            <li>Where You fail to pay the purchase price stated on the invoice or pro-forma invoice (as the case may be), You shall be in default of Your payment obligations under these Terms and Conditions entitling Us in Our sole discretion to, with or without notice, do any or all of the following:</li>
                            <ol type="i">
                                <li>reject or not accept any further Purchase Orders for any Goods and/or Services;</li>
                                <li>require You to recover the Goods from Your location (including the end user&rsquo;s location), and deliver to the address indicated by Us;</li>
                                <li>cancel or amend any credit terms under the confirmation of credit terms if agreed to any in Master Services Agreement or otherwise in writing.</li>
                                <li>In the event that You use the Goods in some manufacturing or construction process of You own or some Third Party, then You must hold such part of the proceeds of sale of such manufacturing or construction process as relates to the Goods in trust for Us. Such part will be an amount equal in dollar terms to the amount owing by You to Us at the time of the receipt of such proceeds. You will pay to Us such funds held in trust upon the demand of Us.</li>
                            </ol>
                        </ol>
                        <li>We utilise third-party providers to deliver support, services, or goods, that are outside of Our internal capacity. Our liability will be limited by the third-party provider&rsquo;s liability agreements and limitations.</li>
                        <li>Where You have engaged your own third-party providers (or internal staff) to complete IT-related works, We will not be held liable for any issues that arise from poor workmanship, faulty Goods/Services, and/or implementation that does not adhere to best practices. This includes any faulty data cabling, electrical works, or IT professional services. We reserve the right to charge You for all remediation works required on a time and materials basis.</li>
                        <li>Reasonable Assistance Limits: We are only obliged to provide what We consider, in Our absolute discretion, to be reasonable assistance in the circumstances (including with the installation and customisation of new software or hardware for You or any other Work) under any Plan and You will pay for additional work at the Rates unless otherwise agreed. Without limiting the discretion of Us to determine what reasonable assistance is, normally, reasonable assistance is limited to work done during Business Hours over a period of time not exceeding any period that We have allowed or allows for the Work or has estimated or estimates the Work will take, whether or not notice of the time allowed or estimated is given by Us to You.</li>
                        <li>Where We have agreed that You can use nominated internal staff for the primary point of contact for IT related requests, you agree that all IT requests will be logged by the nominated contact only. We may refuse to accept and action IT requests from Your staff that are not on the nominated and approved IT contact list.</li>
                        <li>Any variation of these Terms and Conditions must be in writing and signed by Our duly authorised official and Your duly authorised official.</li>
                        <li>Non-Hire/Non-Disclosure/Privacy: Because employees are one of our most valuable assets, policy and professional ethics require that our employees not seek employment with or be offered employment by You during the course of engagement and for a period of one (1) year thereafter. Your signature on this document confirms your organization&rsquo;s agreement to adhere to this professional standard of conduct.</li>
                        <ol type="a">
                            <li>You acknowledge that We are involved in a highly strategic and competitive business. You further acknowledge that You would gain substantial benefit and that We would be deprived of such benefit, if You were to directly hire any personnel employed by Us. Except as otherwise provided by law, you shall not, without the prior written consent of Us, solicit the employment of Our personnel during the term of these Terms and Conditions and for a period of one (1) year following expiration of these Terms and Conditions.</li>
                            <li>You agree that Our damages resulting from breach by You of this provision would be impracticable and that it would be extremely difficult to ascertain the actual amount of damages. Therefore, in the event You violate this provision, you shall immediately pay Us an amount equal to 60% of employee&rsquo;s total annual compensation, as liquidated damages and We shall have the option to terminate these Terms and Conditions without further notice or liability to You. The amount of the liquidated damages reflected herein is not intended as a penalty and is reasonably calculated based upon the projected costs We would incur to identify, recruit, hire and train suitable replacements for such personnel. This Confidentiality, Privacy and Compliance portion of these Terms and Conditions is in addition to other terms and conditions set forth in all contracts currently existing or hereafter created between You and Us. This agreement shall under no circumstances be deemed to alter any such contract except as specifically provided below.</li>
                            <li>We are collecting Your personal information for the fulfilment of Quotes, Orders and the provision of Goods or Services to you and We may retain and use it for any such purposes (&ldquo;Authorised Purposes&rdquo;).</li>
                            <li>You are required to provide your personal information to Us for Authorised Purposes.</li>
                            <li>We may disclose Your personal information to other persons for the purposes of the fulfilment of Quotes, Orders and Work for You or in order to provide Goods or Services to You, to verify the information You provide, for enquiries about Goods or Services that may be suitable for your purposes, or to confirm Your requirements, to anyone proposing to supply Goods or Services to You, or to acquire Goods or Services on Your behalf, or in respect of enquiries relating to any of the foregoing.</li>
                            <li>Otherwise We will not disclose Your personal information without Your consent unless authorised by law.</li>
                            <li>Your personal information will be held by Us at Our principal place of business, or in a secure Cloud or offsite location and Customer can contact Us to request to access or correct it.</li>
                            <li>You shall be entitled to terminate these Terms and Conditions immediately upon Your discretion for a Change of Control of Customer.</li>
                            <li>Both parties shall have the right to terminate these Terms and Conditions immediately where either party ceases to carry on business or suffered an Insolvency Event.</li>
                            <li>We rely on You to submit correct information and details where requested. You accept that You may incur additional expenses if you submit incorrect information.</li>
                            <li>You acknowledge that We may collect Personal Information in connection with these Terms and Conditions in accordance with the Privacy Laws, Our Privacy Policy and any of Our applicable Privacy Statements, and You consent to the Personal Information being collected by Us as required. A copy of Our Privacy Policy, which may be amended from time to time, can be accessed on its website or can be given upon written request.</li>
                            <li>You shall only undertake processing of Personal Information in connection with the performance of Your obligations under these Terms and Conditions and for the purposes expressly authorised by the end user. You shall comply with all requests from the und user from time to time in relation to such processing, including any request from the end user requiring You to amend, transfer or delete the end user Personal Information.</li>
                            <li>You warrant that You have taken all steps necessary, and have ensured that Your agents, contractors and end users have taken all steps necessary (including providing privacy policies/statements to and obtaining privacy consents from relevant individuals) to ensure that We and Our third party contractors are able to access and process (both within and outside Australia) the Personal Information in compliance with all applicable legislation, principles, industry codes and policies which We and third parties are bound by. In particular, You warrant in relation to any Personal Information that You provide to Us that the individual to whom the information relates has consented to the collection of that information and to passing of that information to Us for the purpose of Us further communicating with the individuals in relation to Our services and business activities.</li>
                            <li>You agree to indemnify Us and keep Us indemnified against all claims, proceedings and all liability, loss, costs and expenses incurred in connection therewith, as a result of any claim made or brought by a Third Party in respect of any loss or damage caused to a Third Party as a result of Your failure to comply with Your obligations under the Privacy Laws or under these Terms and Conditions.</li>
                        </ol>
                        <li><strong>Non-Disparagement</strong>During the term and thereafter, You agree not to take any action that is intended to, or would reasonably be expected to, harm Us or Our reputation, or which would reasonably be expected to lead to unwanted or unfavourable publicity to Us.</li>
                        <li>Notices Each notice to be provided under these Terms and Conditions must be in writing. Such notices shall be sent to:</li>
                        <ol type="a">
                            <li>Address: First Floor, Unit 3 1002 Canley Vale Rd Wetherill Park NSW 2164</li>
                        </ol>
                        <li><strong>Force Majeure</strong>Other than with respect to payment obligations hereunder, neither party will be responsible for any failure to perform due to causes beyond its reasonable control including, but not limited to, acts of God, war, riot, embargoes, acts of civil or military authorities, denial of or delays, fire, floods, earthquakes, virus propagation, improper shut down of the Network and related network systems/services. (&ldquo;Force Majeure Event&rdquo;), so long as the affected party provides the other party with prompt written notice describing the Force Majeure Event and immediately continues performance once the Force Majeure Event has been removed or stopped.</li>
                        <li><strong>Your Responsibilities</strong></li>
                        <ol type="a">
                            <li>Lodging of support tickets: For Us to provide You with the agreed Service, you agree to follow Our process for lodging of support tickets which will be provided to you during the on-boarding phase.</li>
                            <li>Access to systems, site and people: In order to provide You with the agreed Service, you agree to give Us access to various items of Yours including but not limited to, equipment, people and sites as and when required.</li>
                            <li>You agree to allow Us to install software on Your Equipment that allows Our technicians to access Your systems at any time. This software allows Us to view system statuses, send monitoring information, see users&rsquo; desktops and control Your PC&rsquo;s. This may require that devices are left on overnight or weekends.</li>
                            <li>Third party authorisations: At times We may need to contact Your third-party providers on Your behalf, such as Your internet provider. Some of these providers may require Your authorisation for Us to deal on Your behalf. It is Your responsibility to ensure that We can deal freely with these providers.</li>
                        </ol>
                        <li><strong>Dispute Resolution</strong>Except as otherwise specifically set forth in these Terms and Conditions, the parties hereby agree to resolve any and all controversies, claims and/or disputes arising out of these Terms and Conditions and/or any Services (each, a &ldquo;Dispute&rdquo;) solely pursuant to the terms of this clause.</li>
                        <ol type="a">
                            <li>If a dispute arises in connection with these Terms and Conditions, a party to the dispute may give to the other party to the dispute notice specifying the dispute and requiring its resolution (&ldquo;Notice of Dispute&rdquo;).</li>
                            <li>If the dispute is not resolved within seven (7) days after the Notice of Dispute is given to the other party, each party to the dispute must nominate one (1) representative from its senior management (&ldquo;Representative&rdquo;) to resolve the dispute.</li>
                            <li>If the dispute is not resolved within seven (7) days of the dispute being referred to the Representative (&ldquo;First Period&rdquo;), the dispute must be submitted to mediation as set out in this clause.</li>
                            <li>If the parties have not agreed on the mediator and the mediator&rsquo;s remuneration within seven (7) days after the First Period, a mediator will be appointed by the President of the Law Society of New South Wales (&ldquo;President&rdquo;); and</li>
                            <li>The remuneration of the mediator will be the amount or fee determined by the President or the President&rsquo;s nominee.</li>
                            <li>The mediation must be conducted in New South Wales.</li>
                            <li>The parties must pay the mediator&rsquo;s remuneration in equal shares. Each party must pay its own costs of the mediation.</li>
                            <li>If the dispute is not resolved within twenty-eight (28) days after the appointment of the mediator, the parties will be free to commence court proceedings.</li>
                            <li>Nothing in this clause diminishes Your obligations to pay by the due date, debts to Us other than those debts specifically the subject of the Notice of Dispute.</li>
                            <li>This clause does not prevent any party from obtaining any injunctive, declaratory or other interlocutory relief from a court, which may be urgently required.</li>
                        </ol>
                        <li><strong>Insurance</strong></li>
                        <ol type="a">
                            <li>We shall maintain at its sole expense Public Liability Insurance; Professional Indemnity insurance; worker&rsquo;s compensation insurance as required by law; and hired and non-owned automobile liability insurance. At Your request, we further agree to furnish You with certificates, including renewal certificates, evidencing such coverage within thirty (30) days of commencing performance under these Terms and Conditions, at every renewal and at other times as may be reasonably requested by You.</li>
                        </ol>
                        <li><strong>General Provisions</strong></li>
                        <ol type="a">
                            <li>Severability/Severance: If a court of competent jurisdiction determines that any terms or provision of these Terms and Conditions are invalid or unenforceable; such determination shall not affect the validity or enforceability of the remaining terms and provisions of these Terms and Conditions, which shall continue to be given full force and effect.</li>
                            <li>Captions: The captions of the paragraphs of these Terms and Conditions are for convenience only and shall not affect in any way the meaning or interpretation of these Terms and Conditions or any of the provisions hereof.</li>
                            <li>Binding Effect: These Terms and Conditions shall be binding upon, and shall inure to the benefit of, the parties hereto and their heirs, legal representatives, personal representatives, administrators, successors, and permitted assigns.</li>
                            <li>Waiver: Any failure of either party to comply with any obligation, covenant, agreement, or condition herein may be expressly waived, but only if such waiver is in writing and signed by the other parties. Any such waiver or failure to insist upon strict compliance with such obligation, covenant, agreement, or conditions shall not operate as a waiver of and/or set precedence with respect to any subsequent and/or other failure.</li>
                            <li>Governing Law: Notwithstanding the place where these Terms and Conditions may be executed by any party, these Terms and Conditions, the rights and obligations of the parties, and any claims and disputes relating hereto shall be subject to and governed by the laws of the State of NSW as applied to agreements among NSW residents to be entered into and performed entirely within the State of NSW, and such laws shall govern all aspects of these Terms and Conditions. The parties agree to submit to the personal jurisdiction and venue of the state and federal courts in the State of NSW, in the Judicial Circuit where We have Our principal office, for resolution of all disputes and causes of action arising out of these Terms and Conditions, and the parties hereby waive all questions of personal jurisdiction and venue of such courts, including, without limitation, the claim or defence therein that such courts constitute an inconvenient forum.</li>
                            <li>Assignment: These Terms and Conditions and the rights and duties hereunder shall not be assignable by either party hereto except upon written consent of the other.</li>
                            <li>Management Resolution: All Disputes shall first be referred to the parties&rsquo; authorized representatives for discussion and resolution of the Dispute (&ldquo;Management Resolution&rdquo;), which representatives are the individuals who have executed these Terms and Conditions on behalf of their party.</li>
                            <li>Attorneys&rsquo; Fees. In any action between the parties to enforce any of the terms of these Terms and Conditions, subject always to the law, the prevailing party shall be entitled to recover all expenses, including reasonable attorneys&rsquo; fees.</li>
                            <li>You acknowledge that a Digitally/Electronically signed Agreement, Contract, Proposal, Quote, or document is legally binding to the full and same extent as physically signed hard copy document.</li>
                            <li>You acknowledge that typographical errors and omissions in these Terms and Conditions, Contract, Proposal, Quote, or document do not nullify, void, or otherwise change the legal binding of said agreement.</li>
                            <li>The parties are independent contractors and both parties agree that no partnership, joint venture, or agency relationship exists between the parties. Additionally, You acknowledge that it is not Our agent of or representative of for any purpose whatsoever and is not granted, under these Terms and Conditions or otherwise, any express or implied right or authority to assume or create any obligation or responsibility on Our behalf or in Our name or to bind Us in any manner whatsoever.</li>
                            <li>If any term of these Terms and Conditions are found to be invalid or unenforceable, the remaining provisions will remain effective and such term shall be replaced with another term consistent with the purpose and intent of these Terms and Conditions.</li>
                            <li>Except for actions for non-payment or breach of the OEM&rsquo;s proprietary rights, no action, regardless of form, arising out of or relating to these Terms and Conditions may be brought by either Party more than two (2) years after the cause of action has accrued.</li>
                            <li>The Goods or Services provided under these Terms and Conditions are not intended for use in nuclear facilities or hazardous applications. You warrant that You are not purchasing the Goods to be used for any other purposes other than the intended purpose for which they were designed for by the OEM and in accordance with the EULA and any other documentation supplied by the OEM.</li>
                            <li>You shall obtain at Your sole expense any rights and consents from Third Parties as is necessary under these Terms and Conditions to allow Customer to transact with Us. You agree to provide Us with all information, access and full good faith and cooperation reasonably necessary to enable Us to provide the Goods and/or Services.</li>
                            <li>You remain solely responsible for its regulatory compliance in connection with its use or resale of the Goods and/or Services. You shall comply with all laws and regulations, including the payment of all fees and taxes and maintaining all licences and permits, applicable to these Terms and Conditions.</li>
                            <li>No waiver of any of Term or Condition or failure to exercise a right or remedy by Us will be considered to imply or constitute a further waiver by Us of the same or any other term, condition, right or remedy. We shall be entitled to invoke any of Our rights under these Terms and Conditions and at law cumulatively at Our will.</li>
                            <li>The purchase of Goods and/or Services or any other goods or service offering available by Us are all separate offers upon Us.</li>
                        </ol>

                    </ol>

                </section>

            </section>

        </>
    )
}

export default Terms